import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
// import VueMq from "vue-mq";
import { firestorePlugin } from "vuefire";
import { store } from "./store";
import i18n from "./i18n";
import MediaPlugin from "@quasar/quasar-ui-qmediaplayer";
import "@quasar/quasar-ui-qmediaplayer/dist/index.css";
import "@fontsource/poppins";
import "@fontsource/noto-sans";

Vue.use(MediaPlugin);

import router from "./router";
import "./quasar";

Vue.config.productionTip = false;
// Vue.use(firestorePlugin);
Vue.use(VueCookies);
Vue.use(VueAxios, axios);
Vue.use(router);
// Vue.use(VueMq, {
//   breakpoints: {
//     mobile: 450,
//     tablet: 900,
//     laptop: Infinity,
//   },
// });

const serialize = (doc) => {
  const data = doc.data();
  Object.defineProperty(data, "id", { value: doc.id });
  Object.defineProperty(data, "_doc", { value: doc });
  return data;
};

Vue.use(firestorePlugin, {
  serialize,
});

Vue.mixin({
  methods: {
    showError(err) {
      console.log(err);
      this.$q.notify({
        type: "negative",
        message: err.toString(),
      });
    },
  },
});

new Vue({
  i18n,
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
