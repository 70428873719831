import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  functions_url: process.env.VUE_APP_FUNCTION_URL,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userSnapshot = await db.doc(`users/${user.uid}`).get();
        const userData = userSnapshot.data();

        Object.assign(user, userData);

        unsubscribe();
        resolve(user);
      } else resolve(null);
    }, reject);
  });
};

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

export const functions = firebase.app().functions(process.env.VUE_APP_REGION);
export const decryptToken = functions.httpsCallable("decryptToken");
export const adminListSubmissions = functions.httpsCallable(
  "adminListSubmissions"
);
export const adminExportSubmissions = functions.httpsCallable(
  "adminExportSubmissions"
);
export const adminListUsers = functions.httpsCallable("adminListUsers");
export const adminListAdmins = functions.httpsCallable("adminListAdmins");
export const copyAsset = functions.httpsCallable("adminCopyAsset");
export const startFeedbackEditing = functions.httpsCallable(
  "startFeedbackEditing"
);
export const startJudgingAllocation = functions.httpsCallable(
  "startJudgingAllocation"
);
export const createJudgingResult = functions.httpsCallable(
  "createJudgingResult"
);
export const createJudgingResultSheet = functions.httpsCallable(
  "createJudgingResultSheet"
);

if (process.env.VUE_APP_EMULATORS) {
  functions.useEmulator("localhost", 5001);
  db.useEmulator("localhost", 8082);
  storage.useEmulator("localhost", 9199);
  auth.useEmulator("http://localhost:9099");
}

// export const auth = firebase.auth();
const { Timestamp, GeoPoint, FieldValue } = firebase.firestore;
export { Timestamp, GeoPoint, FieldValue };
export const functionUrl = firebaseConfig.functions_url;
