<template lang="pug">
.locale-changer
  q-select(
    dark
    dense,
    outlined
    emit-value,
    map-options,
    v-model="$root.$i18n.locale",
    @input="changed",
    :options="langs",
    behavior="menu"
  )
    template(v-slot:prepend)
      q-icon(color="white", name="translate")
</template>

<script>
import { Settings } from "luxon";
let langmap = require("langmap");
let lang_rtl = {
  ur: "ar",
  fa: "ar",
  pa: "ar",
};

export default {
  name: "locale-changer",
  created() {
    this.changed(this.$root.$i18n.locale);
  },
  methods: {
    codeToLang(code) {
      let map = langmap[code];
      return map ? map["nativeName"] : code;
    },
    async changed(val) {
      //set luxon:
      Settings.defaultLocale = val;
      // console.log(val);

      // if (val == "en") val = "en-us";
      // if (val == "ko") val = "ko-kr";
      try {
        let lang = await import(
          /* webpackInclude: /(ar|en-us)\.js$/ */
          "quasar/lang/" + val
        );
        this.$q.lang.set(lang.default);
      } catch (e) {
        let newval = lang_rtl[val] || "en-us";

        console.log(
          `no quasar lang pack for ${val} -- setting to ${newval} instead!`
        );

        let lang = await import("quasar/lang/" + newval);
        this.$q.lang.set(lang.default);
      }
    },
  },
  computed: {
    langs() {
      let lngs = [];
      for (let l of this.$i18n.availableLocales)
        lngs.push({
          label: this.codeToLang(l),
          value: l,
        });
      return lngs;
    },
  },
};
</script>

<style scoped></style>
