import firebase from "firebase/app";
import { firestoreAction } from "vuexfire";
import { db, auth } from "../db";
// import axios from "axios";

const actions = {
  async signInAction({ commit }, payload) {
    if (!payload.token) return;

    try {
      await auth.signInWithCustomToken(payload.token);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  async googleSignInAction({ commit }) {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");

    try {
      const user = await auth.signInWithPopup(provider);
      return user;
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async signOutAction({ commit }) {
    try {
      await auth.signOut();
      commit("setUser", null);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  authAction({ commit }) {
    auth.onAuthStateChanged(async (user) => {
      // console.log("auth state change");
      if (user) {
        // user.updateProfile;
        const userSnapshot = await db
          .collection("users")
          .doc(user.uid)
          .get();
        // console.log(userSnapshot.exists);

        const userData = userSnapshot.data();
        // console.log(userData);
        // const userRole = userData ? userData.roles : null;
        user.roles = userData.roles;
        // Object.assign(user, userData);
        user.ref = userSnapshot.ref;
        user.id = user.uid;
        // console.log(user);
        // Bind submissions for the current user
        // dispatch("bindSubmissions");

        const judging = (
          await db
            .collection("config")
            .doc("meta")
            .get()
        ).data().judging;
        commit("setJudging", judging);
        commit("setUser", user);
      } else {
        commit("setUser", null);
      }
    });
  },
  // async submitAction({ commit, getters }, payload) {
  //   try {
  //     const { submission, files, phase } = payload;
  //     // const country = lookup.byCountry(submission.country);
  //     // const countryISO = country.iso2.toLowerCase();
  //     // const languageISO = ISO6391.getCode(submission.language);

  //     console.log("submission: ", submission);
  //     console.log("files: ", files);
  //     console.log("phase: ", phase);
  //     submission.formdata = { title: submission.title };

  //     // Upload the video to Firebase storage
  //     const newSubmissionRef = db.collection("submissions").doc();
  //     const userRef = db.collection("users").doc(getters.getUser.uid);

  //     const newMediaRefs = [];
  //     const original_key = [];

  //     for (let i = 0; i < files.length; i++) {
  //       if (files[i] !== null) {
  //         const newMediaRef = db.collection("media").doc();
  //         newMediaRefs.push(newMediaRef);

  //         const uploadResult = await storage
  //           .ref()
  //           .child(`submissions/${newSubmissionRef.id}/${newMediaRef.id}.mp4`)
  //           .put(files[i]);
  //         const fileFullPath = uploadResult.ref.fullPath;
  //         original_key.push("/" + fileFullPath);

  //         // Push the Firestore submission entry
  //         await newMediaRef.set({
  //           type: "raw",
  //           createdAt: FieldValue.serverTimestamp(),
  //           submissions: `submissions/${newSubmissionRef.id}`,
  //           src: `s3://youth-innovation/submissions/${newSubmissionRef.id}/${newMediaRef.id}/${newMediaRef.id}_transcoded.mp4`,
  //         });
  //       }
  //     }

  //     await newSubmissionRef.set({
  //       original_key: original_key,
  //       // language: languageISO,
  //       // region: countryISO,
  //       submitted_by: getters.getUser.uid,
  //       status: "submitted",
  //       phase: phase,
  //       media: newMediaRefs,
  //       tags: submission.selectedTags,
  //       formdata: submission.formdata,
  //       createdAt: FieldValue.serverTimestamp(),
  //     });

  //     // Set the submission reference for the current user
  //     await userRef
  //       .collection("submissions")
  //       .doc(`phase_${phase}`)
  //       .set({ submission: `/submissions/${newSubmissionRef.id}` });
  //   } catch (error) {
  //     console.error("Error: ", error);
  //     commit("setError", error.message);
  //   }
  // },
  bindSubmissions: firestoreAction(async ({ bindFirestoreRef }) => {
    if (!auth.currentUser) return;
    const uid = auth.currentUser.uid;
    return bindFirestoreRef(
      "submissions",
      db.collection(`users/${uid}/submissions`)
    );
  }),
  setUserTargetLang: firestoreAction(async ({ state }, payload) => {
    console.log(payload);
    console.log(state);
    await db
      .collection("users")
      .doc(state.user.uid)
      .update({ targetLang: payload });
  }),
  // async startFeedbackEditing() {
  //   try {
  //     await db.startFeedbackEditing();

  //     // const idToken = await auth.currentUser.getIdToken();
  //     // const response = await axios.post(
  //     //   `${functionUrl}/api/judging`,
  //     //   {},
  //     //   {
  //     //     headers: {
  //     //       Authorization: `Bearer ${idToken}`,
  //     //     },
  //     //   }
  //     // );
  //     // console.log("response: ", response);

  //     await db
  //       .collection("config")
  //       .doc("meta")
  //       .update({ judging: false });
  //   } catch (error) {
  //     return error;
  //   }
  // },
  // bindSubmissions: firestoreAction(async ({ bindFirestoreRef }) => {
  //   if (!auth.currentUser) return;
  //   const uid = auth.currentUser.uid;
  //   return bindFirestoreRef(
  //     "submissions",
  //     db.collection(`users/${uid}/submissions`)
  //   );
  // })
};

export default actions;
