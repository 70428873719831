<template lang="pug">
q-layout
  q-page(padding, v-if="!loading")
    div(v-if="invalid")
      div Sorry, this link does not work. Please contact the organiser if you think that you should not have received this error.

    div(v-if="!invalid")
      //- div {{myallocation.code}}
      .text-h5 My Judging Tasks
      q-banner.q-my-lg.bg-warning(rounded v-if="!alldone")
        div Each one of the folowing videos is one of three submitted in response to a specific prompt. Please consider the following when judging:
        ul
          li Please read all the statements in a criteria before making a choice.
          li Select the <strong>most applicable</strong> criteria for that video.
          li The primary purpose of this stage is to find people that would engage well with the Innovation Academy.
          li We are not expecting ideas to be fully formed at this stage.
              
      //- h5 {{currentindex}}
      //- h5 {{steps}}
      //- div {{grouped}}
      div(v-for="(tasks,i) of grouped", v-if="!alldone")
        .q-ml-lg.q-mb-md.text-h5.text-secondary
          q-avatar(color="secondary" text-color="white" size="sm").q-mr-sm
            q-icon(name="check" v-if="somedone(tasks)")
            q-icon(name="edit" v-if="!somedone(tasks)")
          span Submission {{i+1}}
        q-stepper(v-model="steps[i]", vertical, flat, color="primary", animated v-if="!somedone(tasks)")
          q-step(
            v-for="(task,index) of tasks",
            :key="parseInt(index)",
            :prefix="parseInt(index) + 1",
            :name="parseInt(index)",
            :title="'Part ' + (parseInt(index) + 1) + ' of ' + tasks.length",
            :done="task.completed"
          )
            Task(
              :step="steps[i]"
              :task="task",
              :results="results[steps[i]]"
              v-on:complete="save(steps[i], $event)",
              v-on:back="back()"
              :config="config"
            )
          q-step(
            :name="tasks.length",
            :title="'Save Decision for Submission ' + (parseInt(i) + 1)",
            icon="save"
          )
          
            q-stepper-navigation
              .row
                .col
                  q-btn(@click="steps[i]--", color="primary", label="Back" outline)
                .col.text-right
                  q-btn(@click="savesubmission()", color="primary", label="Submit")
        
        
      //- q-stepper(v-model="finalstep", vertical, flat, color="primary", animated)
        q-step(
          :name="myallocation.tasks.length",
          :done="alldone",
          icon="more_horiz",
          title="All Tasks Completed"
        )
      q-banner.q-my-lg.bg-positive.text-white(v-if="alldone" rounded)
        template(v-slot:avatar)
          q-icon(name="check" size="lg")
        div Well done! There is nothing else for you to do here. Thanks for your time.
</template>

<script>
import { db } from "@/db";
import Task from "@/components/Task";
import { size, groupBy, indexOf, filter } from "lodash";

export default {
  name: "Judging",
  props: ["code"],
  components: { Task },
  firestore: {
    config: db.collection("config").doc("meta"),
  },
  meta() {
    return {
      title: "My Judging Tasks",
    };
  },
  computed: {
    myallocation() {
      return this.allocation[0];
    },
    grouped() {
      return Object.values(groupBy(this.myallocation.tasks, "submission.id"));
    },
    groupedWithKeys() {
      return groupBy(this.myallocation.tasks, "submission.id");
    },
    tasklength() {
      return size(this.myallocation.tasks);
    },
    alldone() {
      //return true when all tasks are complete
      let completed = filter(this.myallocation.tasks, "completed");
      return completed.length == this.myallocation.tasks.length;
    },
  },
  methods: {
    somedone(tasks) {
      let completed = filter(tasks, "completed");
      return completed.length == tasks.length;
    },
    back() {
      if (this.steps[this.currentindex] > 0) this.steps[this.currentindex]--;
    },
    async save(step, result) {
      // console.log("next");
      this.results[step] = result;
      this.steps[this.currentindex]++;
      // console.log(this.steps);
    },
    async savesubmission() {
      // Get the task array
      let alltasks = (
        await db
          .collection("judgingallocation")
          .doc(this.myallocation.id)
          .get()
      ).data().tasks;

      // console.log("tasks", tasks);

      let current = this.grouped[this.currentindex];

      // let alltasks = this.myallocation.tasks;

      let j = 0;
      for (let c of current) {
        let indexofthisone = indexOf(this.myallocation.tasks, c);
        // console.log("index", indexofthisone);
        alltasks[indexofthisone].completed = true;
        alltasks[indexofthisone].completedAt = Date.now();
        alltasks[indexofthisone].result = this.results[j];
        j++;
      }

      // Push the result to judgingallocation

      await db
        .collection("judgingallocation")
        .doc(this.myallocation.id)
        .update({ tasks: alltasks });

      this.steps[this.currentindex] = -1;
      this.currentindex++;
      this.steps[this.currentindex] = 0;
      this.results = {};
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$q.loading.show({
            delay: 400, // ms
          });
        } else {
          this.$q.loading.hide();
        }
      },
    },
    code: {
      immediate: true,
      async handler() {
        this.loading = true;
        await this.$bind(
          "allocation",
          db.collection("judgingallocation").where("code", "==", this.code),
          {
            maxRefDepth: 3,
          }
        );

        if (this.allocation.length != 1) {
          this.invalid = true;
        }

        // console.log(this.myallocation);
        // this.step = size(this.myallocation.tasks);
        for (let k in Object.keys(this.groupedWithKeys)) {
          this.$set(this.steps, k, -1);
        }

        // this.steps[0] = 0;

        // let bb = false;

        for (let g of this.grouped) {
          let complete = this.somedone(g);
          // console.log("complete", complete);
          // console.log("g", g);
          if (!complete) {
            // console.log("break");
            break;
          }

          // for (let t of g) {
          //   // console.log(g);
          //   if (!t.completed) {
          //     bb = true;
          //     break;
          //   }
          // }

          // currentindex
          // console.log("bump");
          // if (!bb)
          this.currentindex++;
          // else break;
        }

        // console.log(this.currentindex);

        this.steps[this.currentindex] = 0;

        if (this.alldone) this.steps[this.currentindex] = -1;

        for (const [key, value] of Object.entries(this.myallocation.tasks)) {
          // console.log(value);
          if (!value.completed) {
            this.step = parseInt(key);
            break;
          }
        }

        //valid allocation:
        this.loading = false;
      },
    },
  },
  data: () => {
    return {
      allocation: {},
      loading: true,
      invalid: false,
      config: {},
      finalstep: -1,
      results: {},
      steps: {},
      currentindex: 0,
    };
  },
};
</script>
