<template lang="pug">
q-banner.q-mb-sm.bg-orange.text-white(v-if="terms.lang != null || terms.targetLang != null", rounded, dense inline-actions)
  template(v-slot:action)
    q-btn(flat color="white" icon="close" @click="terms.targetLang = null; terms.lang = null;")
  div
    span(v-if="terms.lang != null && terms.targetLang == null") {{$t('showing_tasks_from',[codeToLang(terms.lang)])}}
    span(v-if="terms.targetLang != null && terms.lang == null") {{$t('showing_tasks_from',[codeToLang(terms.targetLang)])}}
    span(v-if="terms.targetLang != null && terms.lang != null") {{$t('showing_tasks_from_to',[codeToLang(terms.lang),codeToLang(terms.targetLang)])}}
</template>

<script>
import Language from "@/mixins/Language";

export default {
  props: ["terms"],
  mixins: [Language],
};
</script>
