<template lang="pug">
div(v-intersection="scroll_options")
  div(v-if="isFixed" :style="{'height':(filterHeight+5)+'px'}")
  q-card.q-mb-sm(:flat="!isFixed" :square="isFixed" bordered  :class="{'fixed-top z-max q-px-md':isFixed}").q-pa-xs
    q-resize-observer(@resize="onResize")
    
    .row
        .col-12.col-sm-5
            q-select.on-left(
              emit-value,
              map-options,
              dense,
              v-model="terms.lang",
              clearable,
              :options="langs",
              behavior="menu"
              borderless
              :label="$t('source_language')"
            )
              template(v-slot:prepend)
                q-icon(name="translate")
              //- template(v-slot:append)
              //-   q-btn(
              //-     flat,
              //-     dense,
              //-     icon="star"
              //-     :disabled="canSetDefault",
              //-     v-on:click.stop="setUserTargetLang(terms.lang)"
              //-   )
              //-     q-tooltip Make this my default
        //- .col-12.col-sm-5(:class="{'col-md-3':isFixed}")
        //-     q-select.on-left(
        //-       v-if="useTarget"
        //-       emit-value,
        //-       map-options,
        //-       dense,
        //-       v-model="terms.targetLang",
        //-       clearable,
        //-       :options="langs",
        //-       behavior="menu"
        //-       :label="$t('target_language')"
        //-     )
        //-       template(v-slot:prepend)
        //-         q-icon(name="translate")
        //-       //- template(v-slot:append)
        //-       //-   q-btn(
        //-       //-     flat,
        //-       //-     dense,
        //-       //-     icon="star"
        //-       //-     :disabled="canSetDefaultTarget",
        //-       //-     v-on:click.stop="setUserTargetLang(terms.targetLang)"
        //-       //-   )
        //-       //-     q-tooltip Make this my default
        //-       template(v-slot:no-option)
        //-         q-item
        //-           q-item-section.text-grey No results
        .col-12.col-sm-2.text-right(v-if="showLocked" :class="{'col-md-3':isFixed}")
          q-toggle(
            left-label
            v-model="terms.showLocked"
            :label="$t('show_locked_by_me')")

        //- .col-12.col-sm-2.text-right
        //-   q-toggle(
        //-     v-if="useoriginal"
        //-     v-model="useoriginal.use",
        //-     :label="$t('original_language')"
        //-     left-label
        //-     :options="[ { label: $t('original'), value: true }, { label: $t('english'), value: false }, ]"
        //-   )
</template>

<script>
import { each } from "lodash";
let langmap = require("langmap");
import { mapState, mapActions } from "vuex";
// import Language from "@/mixins/Language";

const thresholds = [1];

const uselangs = ["en", "ar", "es", "fr"];

export default {
  // mixins: [Language],
  props: ["terms", "ignoredefault", "useoriginal", "useTarget", "showLocked"],
  data() {
    return {
      lang_options: [],
      isFixed: false,
      scroll_options: {
        handler: this.onIntersection,
        cfg: {
          threshold: thresholds,
        },
      },
    };
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        // console.log(this.user.targetLang);
        if (typeof this.ignoredefault === "undefined") {
          if (
            (this.terms.lang == "" || typeof this.terms.lang === "undefined") &&
            this.user.targetLang
          )
            this.$set(this.terms, "lang", this.user.targetLang);
          // this.terms.lang = this.user.targetLang;
        }
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    langs() {
      let tmp = [];
      each(uselangs, (a) => {
        tmp.push({
          value: a,
          label: `${this.codeToLang(a)["nativeName"]} (${
            this.codeToLang(a)["englishName"]
          })`,
        });
      });
      return tmp;
    },
    canSetDefault() {
      if (!this.user) return true;

      if (typeof this.terms.lang === "undefined" || this.terms.lang == null)
        return true;

      return this.terms.lang == this.user.targetLang;
    },
    canSetDefaultTarget() {
      if (!this.user) return true;

      if (
        typeof this.terms.targetLang === "undefined" ||
        this.terms.targetLang == null
      )
        return true;

      return this.terms.targetLang == this.user.targetLang;
    },
  },
  methods: {
    codeToLang(code) {
      return langmap[code];
    },
    onResize(entry) {
      this.filterHeight = entry.height;
      // console.log(entry);
    },
    onIntersection(entry) {
      // console.log(entry);
      this.isFixed = !entry.isIntersecting;
    },
    ...mapActions(["setUserTargetLang"]),
    filterFnLang(val, update) {
      if (val === "") {
        update(() => {
          this.lang_options = this.langs;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.lang_options = this.langs.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
};
</script>
