import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=241d8ba6&lang=pug&"
import script from "./Task.vue?vue&type=script&lang=js&"
export * from "./Task.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QExpansionItem,QItem,QChip,QForm,QIcon,QField,QOptionGroup,QInput,QStepperNavigation,QBtn});
