import { render, staticRenderFns } from "./ModerationFilter.vue?vue&type=template&id=2144832c&lang=pug&"
import script from "./ModerationFilter.vue?vue&type=script&lang=js&"
export * from "./ModerationFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';import Intersection from 'quasar/src/directives/Intersection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QResizeObserver,QSelect,QIcon,QBtn,QTooltip,QItem,QItemSection,QToggle});qInstall(component, 'directives', {Intersection});
