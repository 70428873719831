<template lang="pug">
</template>

<script>
import { db, auth, FieldValue, decryptToken } from "../db";
// import axios from "axios";

export default {
  name: "Anonymous",
  data() {
    return {
      token: this.$route.query.token,
      user: {},
    };
  },
  created: function() {
    this.$q.loading.show({
      message: this.$t("verifying_the_judging"),
    });
    this.signInAnonymously();
  },
  methods: {
    async signInAnonymously() {
      if (!this.token) {
        console.log("Missing Token...");
        return this.$router.push("/");
      }

      let curCode;
      auth
        .signInAnonymously()
        .then(async (loginData) => {
          this.user = loginData.user;

          // Read result of the decryptToken Function
          const result = await decryptToken({
            requestToken: this.token,
            uid: this.user.uid,
          });
          console.log(result);
          curCode = result.data.code;
          return loginData.user.getIdToken();
        })
        .then(async () => {
          await db
            .collection("anonymousUser")
            .doc(this.user.uid)
            .set({
              code: curCode,
              lastSeen: FieldValue.serverTimestamp(),
            });
          this.$router.push(`/judging/${curCode}`);
        })
        .catch((error) => {
          console.log("Error: " + error);
          this.$q.loading.hide();
          return this.$router.push("/");
        });
    },
  },
};
</script>
