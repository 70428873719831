<template lang="pug">
div
  .row
    .text-overline Reports move through the following stages: 
  .row.items-top
    .col-auto
      q-badge(color="warning") draft
      q-icon(name="east")
      q-badge(color="grey") submitted
      q-icon(name="east")
    .col-auto
      q-badge(color="grey") transcoded
      q-icon(name="east")
      div
        q-icon(name="subdirectory_arrow_right")
        q-badge(color="red") error
      
    .col-auto
      q-badge(color="info") readyformoderation
      div
        q-icon(name="subdirectory_arrow_right")
        q-badge(color="red") rejected
    .col-auto
      q-icon(name="east")
      q-badge(color="grey") moderated
      q-icon(name="east")
    .col-auto
      q-badge(color="grey") edited
      div
        q-icon(name="subdirectory_arrow_right")
        q-badge(color="red") error
    .col-auto
      q-icon(name="east")
      q-badge(color="positive") published
      //- span.q-mx-sm.text-overline OR
      //- q-badge(color="red") error
  .row
    .col
      
  .row 
    .col
      q-table.no-shadow.no-border(
        :data="submissions",
        :columns="submissionColumn",
        :loading="loading",
        row-key="submitted_by",
        :pagination.sync="pagination",
        @request="onRequest"
        :visible-columns="visibleColumns"
      )
        template(v-slot:body="props")
          q-tr(:props="props")              
            q-td(auto-width)
              q-btn(size="md" color="primary" round flat dense @click="props.expand = !props.expand" :icon="!props.expand ? 'expand_more' : 'expand_less'")
            q-td(auto-width)
              span {{ props.row.submitted_by.displayName }}
            q-td(auto-width)
              span {{ props.row.submitted_by.email }}
            q-td(auto-width) {{ (props.row.lastTouched)?getTime(props.row.lastTouched):''}}
            //- q-td(auto-width) {{ codeToFlag(props.row.region) }} 
            //-   q-tooltip {{ codeToName(props.row.region) }} 
            //- q-td(auto-width) {{ codeToLang(props.row.language) }} ({{ codeToLang(props.row.commslanguage) }})
            q-td(auto-width)
              span(:class="[`text-${getChipColor(sub.status)}`]"  :key="sub.id",v-for="sub in props.row.submissions" style="font-size:60px;line-height:28px;") &middot;
            
            //- q-td(auto-width)
            //-   q-btn(icon="flag", flat, round, dense, disabled)
          q-tr(v-show="props.expand" :props="props")
            q-td(colspan="100%" style="max-width:100%;")
              .row.q-col-gutter-xs
                .col-auto(:key="sub.id",v-for="sub in props.row.submissions",)
                  //- div {{getThumbnail(sub.media)}}
                  q-card(flat bordered :class="[`bg-${getChipColor(sub.status)}`]" )
                    .absolute-top-left.z-top.q-pa-xs
                      q-badge(color="grey-8")
                        q-icon(name="east").q-mr-xs
                        span {{ sub.status }} {{sub.error}}
                      q-badge(color="grey-8" v-if="sub.feedback").q-ml-xs
                        q-icon(name="feedback").q-mr-xs
                        span {{ sub.feedback }}
                        q-tooltip Feedback production status
                    .absolute-top-right.z-top.q-pa-xs
                      q-badge(color="positive" v-for="(key,val) in sub.translatedBy" :key="key" v-if="sub.translatedBy").q-mr-xs
                        q-icon(name="language").q-mr-xs
                        span {{ val }}

                        q-tooltip Has {{codeToLang(val)}} translation in YouTube.                     
                    .row
                      
                      q-img.bg-grey-1(:src="getThumbnail(sub)" :ratio="16/9" style="width:100%;min-width:260px;")
                        template(v-slot:error)
                          q-icon(name="image" color="grey" size="xl").absolute-center

                    .column.q-pa-xs
                      .row
                        .col
                          .text-small {{getTime(sub.createdAt)}} &middot; {{ $t(`phase.phase_${sub.phase}`) }}
                          .text-small
                            span(v-if="sub.moderatedBy") Moderated by {{ sub.moderatedBy.displayName }}, {{ getTime(sub.moderatedAt) }}
                            span(v-if="!sub.moderatedBy") ...
                            //- span {{sub.id}}
                          q-tooltip {{ sub.id }}
                        .col-auto
                          q-btn(:href="getDownload(sub)" type="a" target="_BLANK" icon="download" dense flat)
                    q-separator
                    .column
                      
</template>

<script>
  import { adminListSubmissions, db } from "@/db";
  import { DateTime } from "luxon";
  import { ifrcList as regions } from "ifrc-list";
  import langmap from "langmap";
  import { mapState, mapActions } from "vuex";
  import { find, map, filter, includes } from "lodash";

  export default {
    components: {},
    computed: {
      phaseoptions() {
        if (!this.config.phases) return [];
        return map(filter(this.config.phases, "visible"), (m) => {
          return {
            value: m.code,
            label: this.$t("phase.phase_" + m.code),
          };
        });
      },
      ...mapState({
        user: (state) => state.user,
        judging: (state) => state.judging,
      }),
    },
    data: () => {
      return {
        pagination: {
          sortBy: "submitted_by.user_name",
          descending: true,
          page: 1,
          rowsPerPage: 20,
          rowsNumber: -1,
          judgeStarted: false,
        },
        config: {
          current_phase: -1,
        },
        loading: false,
        lastone: {},
        submissions: [],
        terms: {},
        visibleColumns: [],
        submissionColumn: [
          {
            label: "",
            name: "linkedin",
            field: "linkedin",
            align: "left",
            sortable: false,
            required: true,
          },
          {
            label: "Contributor",
            name: "submitted_by.user_name",
            field: "submitted_by.user_name",
            align: "left",
            sortable: true,
            required: true,
          },
          {
            label: "Email",
            name: "submitted_by.email",
            field: "submitted_by.email",
            align: "left",
            sortable: false,
            required: true,
          },
          {
            label: "Last Touched",
            name: "lastTouched",
            align: "left",
            field: "lastTouched",
            sortable: true,
            required: true,

            // format: (val) => `${DateTime.fromSeconds(val._seconds).toRelative()}`,
          },
          {
            label: "NS",
            name: "region",
            field: "region",
            align: "left",
            sortable: false,
          },
          {
            label: "Language (Comms)",
            name: "language",
            field: "language",
            align: "left",
            sortable: true,
          },
          {
            name: "Submissions",
            label: "Reports",
            field: "submissions",
            sortable: false,
            align: "left",
            required: true,
          },
        ],
      };
    },
    watch: {
      terms: {
        deep: true,
        // immediate:true,
        async handler() {},
      },
    },
    mounted() {
      this.onRequest({
        pagination: this.pagination,
      });
    },
    methods: {
      getDownload(submission) {
        return `${this.config.cloudfronturl}/submissions/${submission.id}/hd_video.mp4`;
      },
      getThumbnail(submission) {
        return `${this.config.cloudfronturl}/submissions/${submission.id}/${submission.media}/${submission.media}_transcoded.0000001.jpg`;
      },
      getTime(t) {
        // console.log(t);
        return `${DateTime.fromSeconds(t._seconds).toRelative()}`;
      },
      ...mapActions(["startJudging"]),
      async startJudgingProgress() {
        this.$q.loading.show({
          message: this.$t("waiting_judging"),
        });
        const error = await this.startJudging();
        this.$q.loading.hide();
        if (!error) {
          this.judgeStarted = true;
          this.$q.notify({
            type: "positive",
            message: this.$t("judging_success"),
          });
        } else
          this.$q.notify({
            type: "negative",
            message: this.$t("judging_error"),
          });
      },
      async savePhase() {
        try {
          await db
            .collection("config")
            .doc("meta")
            .update({
              current_phase: this.config.current_phase,
            });
        } catch (e) {
          this.showError(e);
        }
      },
      codeToName(val) {
        // console.log(val);
        if (!val) return "-";
        let f = find(regions, { code: val });
        if (!f) return val;
        return `${f.flag} ${f.name}`;
      },
      codeToFlag(val) {
        // console.log(val);
        if (!val) return "-";
        let f = find(regions, { code: val });
        if (!f) return val;
        return `${f.flag}`;
      },
      codeToLang(val) {
        if (!val) return "-";
        let l = langmap[val.toLowerCase()];
        return l ? l.englishName : val;
      },
      async onRequest(props) {
        this.loading = true;
        let tmp = (await adminListSubmissions(props)).data;

        if (this.pagination.rowsNumber == -1)
          this.pagination.rowsNumber = tmp.meta.total;

        this.pagination.page = tmp.meta.page;
        this.pagination.sortBy = tmp.meta.sortBy;
        this.pagination.descending = tmp.meta.descending;

        this.submissions = tmp.data;
        this.loading = false;
      },
      getChipColor(status) {
        if (status === "readyformoderation") {
          return "info";
        } else if (status === "moderated") {
          return "grey";
        } else if (status === "rejected" || status == "error") {
          return "negative";
        } else if (
          status === "translated" ||
          status === "subtitled" ||
          status == "draft"
        ) {
          return "warning";
        } else if (status === "finalised" || status == "published") {
          return "positive";
        } else {
          return "grey";
        }
      },
      judgingStart() {
        if (!this.user) return false;
        return (
          includes(this.user.roles, "superadmin") &&
          this.judging &&
          !this.judgeStarted
        );
      },
    },
    firestore: {
      config: db.collection("config").doc("meta"),
    },
  };
</script>

<style lang="scss" scoped>
  .text-small {
    font-size: 0.8em;
  }
</style>
