<template lang="pug">
q-banner(rounded).text-white.bg-green
  .text-body1 {{$t('moderation_instructions_title')}}
    ul
      li(v-for="msg of messages.moderation_instructions") {{msg}}

</template>

<script>
export default {
  computed: {
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
  },
};
</script>
