<template lang="pug">
.row
  //- q-scroll-observer(@scroll="onScroll" scroll-target="body")
  q-dialog(v-model="playVideo")
    q-card(style="width: 800px; max-width: 90vm")
      q-media-player(
        v-if="currentVideo",
        :source="getS3Link(currentVideo.src)",
        :ratio="16 / 9"
        autoplay
      )
  //- .col-12
  //-   ModerationFilter(
  //-     :terms="terms",
  //-     ignoredefault,
  //-     :useoriginal="useoriginal",
  //-     :langlabel="$t('source_language')"
  //-   )
  .col-12
    FilterMessage(:terms="terms")
    ModerationInstructions
    q-infinite-scroll(scroll-target="body", ref="scroller", @load="onLoad")
      template(v-slot:loading)
        .row.justify-center.q-my-md
          q-spinner-dots(color="primary", size="40px")

      .text-overline.text-center(v-if="!filtered.length") {{ $t('no_submissions') }}

      q-card.overflow-hidden.row.q-my-sm(
        flat,
        bordered,
        v-for="(submission, index) of filtered",
        :key="submission.id"
        style="max-width:1200px;margin-left:auto;margin-right:auto"
      )
        .col-12
          div.q-pa-xs
            //- q-chip(icon="public") {{ getRegion(submission.region) }}
            q-chip(icon="person") {{ submission.submitted_by.user_name }}
            //- q-chip(icon="language").on-left(rounded) {{ getOriginalLang(submission) }}
            q-chip(icon="history") {{ getTimeFromNow(submission.createdAt) }}
            //- q-chip(icon="list") {{ submission.phase }}
            q-chip.on-left(
              icon="tag"
              rounded,
              v-for="tag of submission.tags",
              :key="tag"
            ) {{ $t('tags.' + tag) }}
          q-separator
        .row.full-width
          .col-12.col-sm-5
            q-responsive.overflow-hidden(
              :ratio="16 / 9",
              style="width: 100%; max-width: 100%",
              :class="{ col: $q.screen.gt.sm }"
            )
              q-carousel(
                animated,
                transition-prev="slide-right",
                transition-next="slide-left",
                arrows,
                control-color="positive"
                v-if="getRawVideos(submission)",
                v-model="activeSlides[index]",
                navigation,
                swipeable,
                infinite
                control-type="regular"
              )
                q-carousel-slide.cursor-pointer.position-relative.overflow-hidden(
                  v-for="(item, idx) in getRawVideos(submission)",
                  :key="item.id",
                  :name="idx",
                  @click="playVideo = true; currentVideo = item",
                  
                )
                  q-img(:src="getThumbnail(item)" style="position:absolute;top:0;left:0;" :ratio="16/9")
                  .bg-red.absolute-bottom.text-white.q-pa-xs.text-center(
                    v-if="getDuration(item) !== false"
                  ) {{ getDuration(item) }}
                  .absolute-top.custom-caption
                    .text-h5 {{ idx + 1 }} of {{ getRawVideos(submission).length }}

          .col-12.col-sm
            q-resize-observer(@resize="onResize")
            q-scroll-area(:style="{'height':`${cellheight}px`}")
              .q-pa-sm
                div(
                  v-for="(media,ind) of getTranscripts(submission)",
                  v-if="",
                  :key="media.id"
                )
                  strong.text-grey-5.text-uppercase Video {{ ind+1 }}
                  .q-mb-sm {{ getText(media) }}


          q-separator(vertical)
          .col-12.col-sm-auto.self-stretch
            q-separator(:vertical="$q.screen.gt.sm")
            q-card-actions.justify-around(:vertical="$q.screen.gt.xs").full-height
              q-btn(
                flat,
                round,
                color="positive",
                icon="check",
                :loading="updating"
                :disable="updating"
                @click="updateStatus(submission, 'moderated')"
              )
                q-tooltip {{ $t('approve') }}

              q-btn(
                flat,
                round,
                color="negative",
                :loading="updating"
                :disable="updating"
                icon="not_interested",
                @click="updateStatus(submission, 'rejected')"
              )
                q-tooltip {{ $t('deny') }}

              q-btn(flat, round, icon="link", @click="shareLink(submission)")
                q-tooltip {{ $t('share_link') }}

  q-page-sticky(
    position="bottom-right",
    :scroll-offset="150",
    :offset="[18, 18]",
    v-if="showFab"
  )
    q-btn-toggle(
      v-if="useoriginal",
      v-model="useoriginal.use",
      toggle-color="primary",
      color="white",
      text-color="primary",
      :options="[ { label: $t('original'), value: true }, { label: $t('english'), value: false }, ]"
    )
    //- q-btn(fab icon="keyboard_arrow_up" color="accent")
</template>

<script>
import { mapState } from "vuex";
import ModerationFilter from "@/components/ModerationFilter";
import FilterMessage from "@/components/FilterMessage";
import ModerationInstructions from "@/components/ModerationInstructions";
import { db, FieldValue } from "@/db";
import { filter, find, last, take } from "lodash";
import { copyToClipboard } from "quasar";
import Language from "@/mixins/Language";
const { ifrcList } = require("ifrc-list");

export default {
  mixins: [Language],
  data: () => {
    return {
      tmpsubs: [],
      terms: {},
      submissions: [],
      loading: false,
      playVideo: false,
      currentVideo: {},
      activeSlides: [],
      useoriginal: {
        use: false,
      },
      updating: false,
      config: {},
      showFab: false,
      cellheight: 240,
    };
  },
  methods: {
    onResize(size) {
      this.cellheight = size.height;
    },
    getTranscripts(submission) {
      // console.log(media);

      let transcripts = filter(submission.media, { modality: "text" });
      let mediaids = take(submission.media, 3);

      let exports = [];

      for (let m of mediaids) {
        let ff = find(transcripts, (t) => t.mediaId == `media/${m.id}`);
        if (ff) exports.push(ff);
      }

      return exports;
    },
    getDuration(media) {
      // console.log(media.duration);
      if (media.duration < 3000 || media.duration > 60000)
        return this.$t("video_length", [Math.round(media.duration / 1000)]);
      else return false;
    },
    getS3Link(src) {
      if (!src) return "";
      return `${this.config.cloudfronturl}${src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")}`;
    },
    getThumbnail(media) {
      return `${this.getS3Link(media.src).replace(".mp4", "")}.0000001.jpg`;
    },
    getRawVideos(submission) {
      let vids = filter(submission.media, { type: "raw" });
      return vids;
    },
    getMediaField(type, submission) {
      let lf = filter(submission.media, { type: type });
      if (!lf) return null;
      return lf;
    },
    getOriginalLang(submission) {
      return this.codeToLang(submission.language);
    },
    getRegion(reg) {
      return find(ifrcList, { code: reg }).name;
    },
    getText(media) {
      if (this.useoriginal.use)
        return find(media.langs, { original: true }).srcText;
      else {
        let translated = find(media.langs, { targetLang: "en" });
        if (translated) return translated.targetText;
        else return find(media.langs, { original: true }).srcText;
      }
    },
    getTextField(submission, type) {
      let lf = find(submission.media, { type: type });
      if (!lf) return null;
      return this.getText(lf);
    },
    async updateStatus(submission, status) {
      // console.log("Change status", status);
      this.updating = true;
      try {
        if (status == "rejected")
          this.$q
            .dialog({
              title: this.$t("reject_submission"),
              message: this.$t("reject_reason"),
              prompt: {
                model: "",
                type: "text", // Optional
              },
              cancel: true,
              persistent: true,
            })
            .onOk(async (data) => {
              await db
                .collection("submissions")
                .doc(submission.id)
                .update({
                  status: status,
                  rejectreason: data,
                  moderatedBy: `users/${this.user.id}`,
                  moderatedAt: FieldValue.serverTimestamp(),
                });
              submission.status = status;
            });
        else {
          await db
            .collection("submissions")
            .doc(submission.id)
            .update({
              status: status,
              moderatedBy: `users/${this.user.id}`,
              moderatedAt: FieldValue.serverTimestamp(),
            });
          submission.status = status;
        }
      } finally {
        this.updating = false;
      }
    },
    async shareLink(submission) {
      try {
        const shareLink = `${location.host}/moderation/${submission.id}`;
        await copyToClipboard(shareLink);
        this.$q.notify({
          type: "positive",
          icon: "check_circle_outline",
          message: this.$t("successfully_copied_moderation_link"),
        });
      } catch (e) {
        this.showError(e);
      }
    },
    async onLoad(index, done) {
      // console.log("loading....");
      await this.getSubmissions(done);
      // console.log("submissions: ", this.submissions);
    },
    async getSubmissions(done) {
      try {
        let ref = db
          .collection("submissions")
          .where("status", "==", "readyformoderation")
          .orderBy("createdAt", "asc");

        if (this.terms.lang) {
          ref = db
            .collection("submissions")
            .where("status", "==", "readyformoderation")
            .where("language", "==", this.terms.lang)
            .orderBy("createdAt", "asc");
        }

        if (this.submissions.length > 0) {
          let lastl = last(this.submissions);
          // let lastref = await db.collection("submissions").doc(last.id).get();
          ref = ref.startAfter(lastl._doc);
        }

        ref = ref.limit(5);

        await this.$bind("tmpsubs", ref, {
          maxRefDepth: 3,
        });

        this.tmpsubs.forEach((doc) => {
          this.submissions.push(doc);
        });

        let length = this.tmpsubs.length;

        // console.log("tmpsubs length: ", length);

        this.$unbind("tmpsubs", true);

        // Prevent from receiving updates automatically

        if (done) done(length == 0);
        else this.$refs.scroller.stop();
      } catch (e) {
        console.log(e);
        this.showError(e);
      }
    },
    updateActiveSlide(length) {
      this.activeSlides = Array(length).fill(0);
    },
  },
  watch: {
    terms: {
      deep: true,
      async handler() {
        this.submissions = [];
        this.$refs.scroller.reset();
        this.$refs.scroller.resume();
        this.$refs.scroller.trigger();
      },
    },
  },
  components: {
    ModerationFilter,
    FilterMessage,
    ModerationInstructions,
  },
  computed: {
    filtered() {
      const filteredSubmission = filter(this.submissions, {
        status: "readyformoderation",
      });
      this.updateActiveSlide(filteredSubmission.length);
      return filteredSubmission;
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  firestore: {
    config: db.collection("config").doc("meta"),
  },
};
</script>

<style lang="sass" scoped>
.custom-caption
  text-align: center
  padding: 12px
  color: white
  background-color: rgba(0, 0, 0, .3)
</style>
