<template lang="pug">
div(:class="{ 'light-dimmed': task.completed }") 
  div
    q-media-player(
      cross-origin="anonymous",
      style="border-radius: 0.3rem 0.3rem 0 0",
      type="video",
      :disabled-seek="true",
      background-color="black",
      :loop="true",
      :bottom-controls="false",
      :show-big-play-button="true",
      :sources="video.sources",
      :tracks="video.tracks",
      ref="player",
      @timeupdate="updateTime",
      track-language="English"
    )
    q-list.q-mb-lg(bordered, separator)
      q-expansion-item(
        icon="subject",
        dense,
        dense-toggle,
        @before-show="getCaptions()",
        :label="$t('full_transcript')"
      )
        .q-pa-md
          span(v-for="cap in captions") {{ cap.part }}
      q-item
        div
          q-chip.text-white(
            color="primary",
            v-for="tag in task.submission.tags",
            :key="tag"
          ) {{ $t('tags.' + tag) }}
          q-chip(outline, color="primary") {{ codeToLang(task.submission.language) }}

  q-form(ref="theform")
    .text-body1.q-mb-md.text-info
      q-icon.on-left(name="info")
      span This video is a response to the prompt: &quot;{{ task.criteria.description }}&quot;
    div(v-for="(criteria, index) in task.criteria.criteria")
      .text-body2.text-bold {{ criteria.title }}
        span.text-grey-6(v-if="criteria.optional") (optional)

      q-field(
        v-if="criteria.steps",
        :ref="'criteria_' + index",
        v-model="result[index]",
        borderless,
        :rules="[(val) => hasValue(val) || $t('complete_scale')]",
        lazy-rules="ondemand"
      )
        q-option-group(
          v-model="result[index]",
          :options="getScaleOptions(criteria)",
          color="primary"
        )

      q-input.q-mt-sm(
        v-if="criteria.field == 'text'",
        v-model="result[index]",
        autogrow,
        outlined,
        dense,
        bottom-slots,
        counter,
        maxlength="100"
      )
  q-stepper-navigation
    .row
      .col
        q-btn(v-if="step>0" @click="back()", color="primary", label="Back" outline)
      .col.text-right
        q-btn(@click="complete()", color="primary", label="Continue")
</template>

<script>
import { find, filter } from "lodash";
import vttToJson from "vtt-json";
import axios from "axios";
import Language from "@/mixins/Language";

export default {
  props: ["step", "task", "config", "results"],
  mixins: [Language],
  data: () => {
    return {
      result: {},
      rawcaptions: {},
    };
  },
  mounted() {
    if (this.results) this.result = { ...this.results };
  },
  computed: {
    captions() {
      // Filter captions where within in and out point:
      let caps = filter(this.rawcaptions, (raw) => {
        // console.log(parseInt(raw.start) / 1000);
        return (
          parseInt(raw.start) / 1000 >= this.inpoint &&
          parseInt(raw.end) / 1000 <= this.outpoint
        );
      });
      return caps;
    },
    inpoint() {
      let hd_video = find(this.task.submission.media, (media) => {
        return media && media.type === "hd_video";
      });
      console.log(hd_video);
      let segment = hd_video.clips[this.task.section];
      return segment.start;
    },
    outpoint() {
      let hd_video = find(this.task.submission.media, (media) => {
        return media && media.type === "hd_video";
      });
      let segment = hd_video.clips[this.task.section];
      return segment.end;
      // use task.section to work this out
    },
    getInOut() {
      return `t=${this.inpoint},${this.outpoint}`;
    },
    getVideoSrc() {
      let hd_video = find(this.task.submission.media, (media) => {
        // console.log("media: ", media);
        return media && media.type === "hd_video";
      });
      if (!hd_video.src) return "";
      return `${this.config.cloudfronturl}${hd_video.src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")
        .replace("hd_video", "hd_video_transcoded")}#${this.getInOut}`;
    },
    getTitlesSrc() {
      let hd_video = find(this.task.submission.media, (media) => {
        return media && media.type === "hd_video";
      });
      if (!hd_video.src) return "";
      let src = `${this.config.cloudfronturl}${hd_video.src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")
        .replace("hd_video.mp4", `youtube_en.vtt`)}`;
      return src;
    },
    video() {
      return {
        sources: [
          {
            src: this.getVideoSrc,
            type: "video/mp4",
          },
        ],
        tracks: [
          {
            src: this.getTitlesSrc,
            kind: "subtitles",
            srclang: "en",
            label: "English",
          },
        ],
      };
    },
  },
  methods: {
    getTextField() {
      let phase = find(this.config.phases, {
        code: this.task.submission.phase,
      });
      let segmentname = "";
      try {
        segmentname = phase.videoorder[this.task.section];
      } catch {
        segmentname = "title";
      }

      let media = find(this.task.submission.media, { type: segmentname });
      let translated = find(media.langs, { targetLang: "en" });
      if (translated) return translated.targetText;
      else return find(media.langs, { original: true }).srcText;
    },
    async getCaptions() {
      try {
        let data = (await axios.get(this.getTitlesSrc)).data;
        this.rawcaptions = await vttToJson(data);
      } catch (e) {
        console.log("load captions error");
        console.log(e);
      }
    },
    updateTime(seconds) {
      if (seconds > this.outpoint) {
        this.$refs.player.pause();
        this.$refs.player.setCurrentTime(this.inpoint);
        return;
      }

      if (seconds < this.inpoint) {
        this.$refs.player.setCurrentTime(this.inpoint);
        return;
      }
    },
    hasValue(val) {
      return typeof val !== "undefined";
    },
    async complete() {
      //validate:
      if (await this.$refs.theform.validate()) {
        // this.task.result = this.result;
        // console.log(this.task.result);
        this.$emit("complete", this.result);
      }
    },
    back() {
      this.$emit("back");
    },
    getScaleOptions(criteria) {
      let scale = [];
      for (const [key, value] of Object.entries(criteria.steps)) {
        scale.push({
          label: value,
          value: key,
        });
      }
      return scale;
    },
  },
};
</script>
