<template lang="pug">
.row
    .col-auto
      q-tabs(vertical class="text-grey-7" active-color="primary" indicator-color="transparent").q-mt-md
        q-route-tab(:to="{ params: { section: 'reports' } }" label="Reports" icon="analytics" name="reports")
        q-route-tab(:to="{ params: { section: 'settings' } }" label="Settings" icon="settings" name="settings")
        q-route-tab(label="Team" icon="people" name="team" :to="{ params: { section: 'team' } }")
        q-route-tab(label="Judging" icon="gavel" name="judging" :to="{ params: { section: 'judging' } }")
        q-route-tab(label="Tools" icon="build" name="tools" :to="{ params: { section: 'tools' } }")
        q-route-tab(label="Phases" icon="list" name="phases" :to="{ params: { section: 'phases' } }")

    .col
      q-tab-panels(v-model="section" vertical)
        q-tab-panel(name="judging")
          div(flat ).q-mb-md
            .q-pa-sm
              .text-h6 Judging
              .text-grey Create judging allocations
            //- q-separator
            .q-pa-sm
              .row.q-col-gutter-sm.items-center
                .col-6
                  q-select(:options="phaseoptions" v-model="judging.phase" dense flat outlined emit-value map-options label="Select Phase")
                .col-6
                  q-select(:options="langs" v-model="judging.lang" dense flat outlined emit-value map-options label="Select Language")
              
              .row.q-col-gutter-sm.text-center.items-center.q-mt-sm
                .col-3
                  .text-grey-6 Create an Excel sheet anonymous judging links for currently un-allocated submissions.
                .col-3
                  .text-grey-6 Create an Excel sheet of the current judging progress.
                .col-3
                  .text-grey-6 Initiate editing of custom feedback videos for any fully complete judging.
                .col-3
                  .text-grey-6 Create an Excel sheet of the currently completed feedback for communications.

              .row.q-col-gutter-sm.items-center
                .col-3.text-center
                  q-btn.q-px-md(
                    color="primary",
                    @click="doStartJudgingAllocation",
                    :disable="!judging.phase || !judging.lang"
                    label="Create Judging Allocations"
                  )
                .col-3.text-center
                  q-btn.q-px-md(
                    color="primary",
                    @click="doGetResultsSheet",
                    :disable="!judging.phase"
                    label="Create Initial Results"
                  )
                .col-3.text-center
                  q-btn.q-px-md(
                    color="primary",
                    :disable="!judging.phase"
                    @click="doStartFeedbackEditing",
                    label="Create Feedback Videos"
                  )
                .col-3.text-center
                  q-btn.q-px-md(
                    color="primary",
                    :disable="!judging.phase"
                    @click="doGetFullResultsSheet",
                    label="Create Email List"
                  )
                .col-12
                  q-list(separator)
                    q-item(v-for="file of judgingSheets" :key="file.name")
                      q-item-section {{file.name}}
                      q-item-section(side)
                        q-btn(flat dense round icon="download" @click="getDownloadLink(file)" )
          
          
          .row.items-center.q-mb-md
            .col
              .text-h6 Criteria
              .text-grey Create judging criteria
            .col-auto 
              q-btn(icon="save" flat color="primary" @click="saveCriteria" :loading="savingCriteria" :disable="savingCriteria")
            //- div {{judgingCriteria}}
            //- div(v-for="phase in config.phases")
            //- .text-h6 Phase "{{phase.code}}"

          q-tabs(align="left" dense v-model="selectedPhase" active-color="primary")
            q-tab(v-for="(phase,y) in config.phases" :name="phase.code" :label="phase.code" :key="`${y}-${phase.code}`")

          q-separator  
          q-tabs(align="left" dense v-model="selectedVideo" active-color="primary")
            q-tab(v-for="(n,index) in getNumberVideos(selectedPhase)" :label="`Video ${index+1}`" :name="index" :key="index")
            
          q-separator

          div.q-mb-sm.q-py-sm

            div(v-if="getCurrentCriteria().empty")
              q-btn(link dense flat @click="addCriteria()") Add Criteria for this phase and video
            div(v-else)
              .text-overline.text-grey-6 Video Title
              q-input.q-mb-lg(v-model="getCurrentCriteria().description" dense flat outlined)
              
              div(v-for="(question,ind) in getCurrentCriteria().criteria" :key="`${ind}`").q-mb-sm
                .row
                  .col
                    .text-overline.text-grey-6 Prompt {{ind+1}}
                  .col-auto
                    q-btn(icon="delete" dense flat round @click="removePrompt(getCurrentCriteria().criteria,ind)" color="grey-6")
                q-input(v-model="question.title" dense flat filled)
                .row.items-center
                  .col
                    .text-overline.text-grey-6 Response {{ind+1}}
                  .col-auto
                    q-checkbox(v-model="question.optional" label="Optional" :false-value="null")
                    q-checkbox(v-model="question.field" label="Free Text" true-value="text" :false-value="null" @input="updateFreeText(selectedPhase,question)")
                q-input(v-model="question.steps[index]"  dense flat filled  v-for="(n,index) in 3" :key="`q-${n}`" v-if="question.steps && question.field!=='text'").q-mb-xs
                  template(v-slot:before) {{n}}
                q-separator.q-mt-md

              q-btn(link dense flat @click="addPrompt(getCurrentCriteria())") Add Prompt
                
            //- q-separator.q-mt-md

              
              
          //- q-list(separator)
            q-item()
              q-item-section {{criteria}}

        q-tab-panel(name="reports")
          .q-mb-md
            q-card-section.q-pa-sm
              .text-h6 Contributor Email List
              .text-grey Produces a list of contributors who have have at least 1 published submission.
            q-card-section.q-pa-sm
              q-btn(outline color="primary" @click="getUsers" :loading="downloadUsers") Download CSV
            
            q-card-section.q-pa-sm
              .text-h6 Full Submission List
              .text-grey Produces a list of all submissions.
            q-card-section.q-pa-sm
              q-btn(outline color="primary" @click="getSubmissionsExport" :loading="downloadUsers") Download CSV

        q-tab-panel(name="tools")
            .q-mb-md
              q-card-section.q-pa-sm
                .text-h6 Manual Edit (top+tail)
                .text-grey These videos become branded, but are not included in the moderation or publishing pipeline.
              
              q-card-section.q-pa-sm
                .text-overline Note: Videos MUST have audio, and they should be full HD (1920x1080) in order to get the watermark applied correctly. Videos take approx. 3 mins to edit, before which the download link below will not work.
                .text-overline If a video fails to edit, the link will be invalid.
                
                q-file.q-mt-md(
                  ref="formInput",
                  v-model="inputVal",
                  label="File to Edit"
                  outlined,
                  accept=".mp4, video/*",
                  :disable="uploading"
                  lazy-rules,
                  :rules="[(val) => !!val || $t('video_required')]"
                )
                  template(v-slot:after)
                    q-btn.full-height(@click="upload" color="primary"  :loading="uploading" :percentage="progress" :disable="uploading") Upload
              q-separator
              q-list(separator)
                q-item(v-for="file of files" :key="file.name")
                  q-item-section {{file.name}}
                  q-item-section(side)
                    q-btn(flat dense round icon="download" type="a" :href="`${config.cloudfronturl}/dropbox/${file.name}/hd_video.mp4`")

        q-tab-panel(name="team")
          .q-mb-md
            q-card-section.q-pa-sm
              .text-h6 Team
              .text-grey These people have access to this dashboard with a Google enabled account. Invitees are not automatically notified.

            q-item
              q-item-section
                q-input(v-model="newperson.email" borderless dense placeholder="email to invite")
              q-item-section(side)
                q-checkbox(dense v-model="newperson.roles" val="superadmin") Super
              q-item-section(side)
                q-checkbox(dense v-model="newperson.roles" val="admin") Admin
              q-item-section(side)
                q-checkbox(dense v-model="newperson.roles" val="moderator") Mod.
              q-item-section(side)
                q-btn(@click="addInvite" flat label icon="add" color="primary")
            q-separator
            
            q-list(separator)
                q-item(v-for="(person) of team" :key="person.id")
                  q-item-section(v-if="!person.invite")
                    span {{person.auth.displayName}}
                    span.text-grey {{person.auth.email}}
                  q-item-section(v-if="person.invite")
                    .text-orange Invited
                    span.text-grey {{person.auth.email}}
                  q-item-section(side v-if="person.invite")
                    q-btn(icon="close" dense flat @click="cancelInvite(person)")

                  q-item-section(side)
                    q-checkbox(dense v-model="person.user.roles" val="superadmin" @input="updateRole($event,person)") Super
                  q-item-section(side)
                    q-checkbox(dense v-model="person.user.roles" val="admin" @input="updateRole($event,person)") Admin
                  q-item-section(side)
                    q-checkbox(dense v-model="person.user.roles" val="moderator" @input="updateRole($event,person)") Mod.

        q-tab-panel(name="settings")
          .q-mb-lg
            q-card-section.q-pa-sm
              .text-h6 Global Settings
              .text-grey Settings apply to whole pipeline.
            q-separator
            q-card-section
              .row.items-center.justify-center
                .col-12.text-body1.text-orange CAREFUL! Switching the phase will prevent any reports in other phases for EVERYONE.
                .col-12
                  q-btn-toggle.text-uppercase.q-mt-xs(
                    v-model="config.current_phase",
                    toggle-color="primary",
                    :options="phaseoptions",
                    
                    outline
                    @input="savePhase"
                  )
            q-card-section.q-pa-sm
              .text-bold Publishing
              q-toggle(v-model="config.youtube" label="Publish to YouTube")
              q-toggle(v-model="config.privacy" false-value="unlisted" :disabled="!config.youtube" true-value="public" label="Publish Public")
              q-input(v-model="socialMedia.hashtag" filled dense label="Hashtag for production").q-mt-xs
              .text-bold Translation
              q-toggle(v-model="config.sendToActionTranslate" label="Send to Action Translate")
              q-input(v-model="config.translationTitle" filled dense label="Title of job in ActionTranslate").q-mt-xs
              q-input.q-mb-sm(v-model="config.translationDescription" filled dense label="Description of job in ActionTranslate").q-mt-sm
              .text-bold YouTube Login
              q-btn.q-mt-md(color="primary" :href="`${functionUrl}/startYouTubeAuth`" type="a") Connect YouTube Account

     
        
        q-tab-panel(name="phases")
          div(v-if="config.opensAt")
            q-card-section.q-pa-xs
              .row.items-center
                .col
                  .text-h6.q-pl-xs Phases 
                .col-auto
                  q-select(:options="langs" v-model="lang" dense emit-value,borderless
                  map-options)
              .row 
                .col.q-pl-xs
                  .text-grey Configure phases of a deployment and associated content.
            q-separator
            q-card-section.q-pa-sm
              q-timeline
                q-timeline-entry(title="Opens")
                  .row.q-col-gutter-sm
                    .col-auto
                      q-input(:value="getTime(config.opensAt)" filled dense)
                        template(v-slot:append)
                          q-icon(name="access_time" class="cursor-pointer")
                            q-popup-proxy(ref="qTimeProxy" transition-show="scale" transition-hide="scale")
                              q-time(:value="getTime(config.opensAt)" @input="updateTimeC($event,'opensAt');")
                                div.row.items-center.justify-end
                                  q-btn(v-close-popup label="Close" color="primary" flat)
                    .col-auto
                      q-input(:value="getDate(config.opensAt)" filled dense)
                        template(v-slot:append)
                          q-icon(name="event" class="cursor-pointer")
                            q-popup-proxy(ref="qDateProxy" transition-show="scale" transition-hide="scale")
                              q-date(:value="getDate(config.opensAt)" @input="updateDateC($event,'opensAt');")
                                div.row.items-center.justify-end
                                  q-btn(v-close-popup label="Close" color="primary" flat)

                q-timeline-entry(v-for="(phase,key) of config.phases" :key="key" flat bordered)
                  q-expansion-item(group="phases" :label="phase.code" header-class="text-h6 q-px-none text-capitalize" style="margin-top:-10px;" dense)
                    .row.q-col-gutter-sm.items-center.q-mb-sm
                      .col-1.text-right Has Submission
                      .col-auto
                        q-toggle(v-model="phase.hasSubmission" dense)
                    .row.q-col-gutter-sm.items-center.q-mb-sm
                      .col-1.text-right Visible
                      .col-auto
                        q-toggle(v-model="phase.visible" dense)
                    div(v-if="phase.hasSubmission")

                      .row.q-col-gutter-sm.items-center.q-mb-sm
                        .col-1.text-right Allow Team Submission
                        .col-auto
                          q-toggle(v-model="phase.team" dense)
                      .row.q-col-gutter-sm.items-center.q-mb-sm
                        .col-1.text-right # Videos
                        .col-auto
                          q-input(v-model="phase.noOfVideos" dense type="number" filled)
                          .row.q-col-gutter-sm.items-center.q-mb-sm
                      .row.q-col-gutter-sm.items-center.q-mb-sm
                        .col-1.text-right Max Submissions
                        .col-auto
                          q-input(v-model="phase.maxSubmissions" dense type="number" filled)
                      .row.q-col-gutter-sm.items-center.q-mb-sm
                        .col-2.text-right
                          .text-overline Opens
                        .col-auto
                          q-input(:value="getTime(phase.opensAt)" filled dense)
                            template(v-slot:append)
                              q-icon(name="access_time" class="cursor-pointer")
                                q-popup-proxy(ref="qTimeProxy" transition-show="scale" transition-hide="scale")
                                  q-time(:value="getTime(phase.opensAt)" @input="updateTime($event,key,'opensAt');")
                                    div.row.items-center.justify-end
                                      q-btn(v-close-popup label="Close" color="primary" flat)
                        .col-auto
                          q-input(:value="getDate(phase.opensAt)" filled dense)
                            template(v-slot:append)
                              q-icon(name="event" class="cursor-pointer")
                                q-popup-proxy(ref="qDateProxy" transition-show="scale" transition-hide="scale")
                                  q-date(:value="getDate(phase.opensAt)" @input="updateDate($event,key,'opensAt');")
                                    div.row.items-center.justify-end
                                      q-btn(v-close-popup label="Close" color="primary" flat)
                      .row.q-col-gutter-sm.items-center
                        .col-2.text-right
                          .text-overline Closes
                        .col-auto
                          q-input(:value="getTime(phase.closesAt)" filled dense)
                            template(v-slot:append)
                              q-icon(name="access_time" class="cursor-pointer")
                                q-popup-proxy(ref="qTimeProxy" transition-show="scale" transition-hide="scale")
                                  q-time(:value="getTime(phase.closesAt)" @input="updateTime($event,key,'closesAt');")
                                    div.row.items-center.justify-end
                                      q-btn(v-close-popup label="Close" color="primary" flat)
                        .col-auto
                          q-input(:value="getDate(phase.closesAt)" filled dense)
                            template(v-slot:append)
                              q-icon(name="event" class="cursor-pointer")
                                q-popup-proxy(ref="qDateProxy" transition-show="scale" transition-hide="scale")
                                  q-date(:value="getDate(phase.closesAt)" @input="updateDate($event,key,'closesAt');")
                                    div.row.items-center.justify-end
                                      q-btn(v-close-popup label="Close" color="primary" flat)
                      .row 
                        .col-2.text-right
                          .text-overline YouTube Title
                        .col
                          EditInput(:value="socialMedia[`youtube_title_${phase.code}`]" :field="`youtube_title_${phase.code}`" :lang="lang")
                      .row  
                        .col-2.text-right
                          .text-overline YouTube Description 
                        .col
                          EditInput(:value="socialMedia[`youtube_description_${phase.code}`]" :field="`youtube_description_${phase.code}`" :lang="lang")
                      .row
                        .col-2.text-right
                          .text-overline Social Post
                        .col
                          EditInput(:value="socialMedia[`posts_${phase.code}`]" :field="`posts_${phase.code}`" :lang="lang")
                      .row.items-center
                        .col-2.text-right
                          .text-overline Individual Transitions
                        .col
                          q-toggle(v-model="phase.individual_transitions")
                      .row.q-col-gutter-sm
                        .col-4
                          UploadAsset(title="Intro" :phase="phase.code" :lang="lang" :config="config" name="intro" v-on:lengthUpdated="updateLength($event, key, 'intro_length')" :length="phase.intro_length")
                        .col-4(v-if="!phase.individual_transitions")
                          UploadAsset(title="Transition" :phase="phase.code" :lang="lang" :config="config" name="transition" v-on:lengthUpdated="updateLength($event, key, 'transition_length')" :length="phase.transition_length")
                        .col-4(v-if="phase.individual_transitions" v-for="i in parseInt(phase.noOfVideos)-1")
                          UploadAsset(:title="`Transition ${i}`" :transitionindex="i" :phase="phase.code" :lang="lang" :config="config" :name="`transition_${i-1}`" v-on:lengthUpdated="updateLength($event, key, 'transition_length')" :length="phase.transition_length")
                        .col-4
                          UploadAsset(title="Outro" :phase="phase.code" :lang="lang" :config="config" name="outro")
                        .col-4
                          UploadAsset(title="Watermark" :phase="phase.code" :lang="lang" :config="config" name="watermark" :ext="'.mov'")  
                        .col-4
                          UploadAsset(title="Overlay" :phase="phase.code" :lang="lang" :config="config" name="overlay" :image="true" :overlay="true")


                      

                q-timeline-entry(title="Closes")
                  .row.q-col-gutter-sm
                    .col-auto
                      q-input(:value="getTime(config.closesAt)" dense filled)
                        template(v-slot:append)
                          q-icon(name="access_time" class="cursor-pointer")
                            q-popup-proxy(ref="qTimeProxy" transition-show="scale" transition-hide="scale")
                              q-time(:value="getTime(config.closesAt)" @input="updateTimeC($event,'closesAt');")
                                div.row.items-center.justify-end
                                  q-btn(v-close-popup label="Close" color="primary" flat)
                    .col-auto
                      q-input(:value="getDate(config.closesAt)" filled dense)
                        template(v-slot:append)
                          q-icon(name="event" class="cursor-pointer")
                            q-popup-proxy(ref="qDateProxy" transition-show="scale" transition-hide="scale")
                              q-date(:value="getDate(config.closesAt)" @input="updateDateC($event,'closesAt');")
                                div.row.items-center.justify-end
                                  q-btn(v-close-popup label="Close" color="primary" flat)

  
  
</template>

<script>
import {
  db,
  functionUrl,
  adminListUsers,
  adminListAdmins,
  storage,
  startFeedbackEditing,
  startJudgingAllocation,
  createJudgingResult,
  createJudgingResultSheet,
} from "@/db";
// import firebase from "firebase/app";
import "firebase/storage";
import { DateTime } from "luxon";

import { mapState } from "vuex";
import { map, filter, includes, each, range, find } from "lodash";
import { exportFile } from "quasar";
import EditInput from "../EditInput.vue";
import UploadAsset from "../UploadAsset.vue";
let langmap = require("langmap");
import { openURL } from "quasar";
import { adminExportSubmissions } from '../../db';

// const uuid = require("uuid");

export default {
  props: ["section"],
  components: { EditInput, UploadAsset },
  computed: {
    phaseoptions() {
      if (!this.config.phases) return [];
      return map(filter(this.config.phases, "visible"), (m) => {
        return {
          value: m.code,
          label: this.$t("phase.phase_" + m.code),
        };
      });
    },
    ...mapState({
      user: (state) => state.user,
      // judging: (state) => state.judging,
    }),
    langs() {
      let tmp = [];
      each(this.config.target_language, (a) => {
        tmp.push({
          value: a,
          label: `${this.codeToLang(a)["nativeName"]} (${
            this.codeToLang(a)["englishName"]
          })`,
        });
      });
      return tmp;
    },
  },
  data: () => {
    return {
      config: {
        current_phase: -1,
      },
      selectedVideo: -1,
      selectedPhase: "",
      socialMedia: {},
      judgingCriteria: [],
      inputVal: null,
      loading: false,
      uploading: false,
      progress: 0,
      files: [],
      judgingSheets: [],
      downloadUsers: false,
      lang: "en",
      team: [],
      functionUrl: functionUrl,
      newperson: { email: "", roles: [] },
      judging: {
        phase: null,
        lang: null,
      },
      savingCriteria: false,
    };
  },
  watch: {
    selectedPhase: {
      handler() {
        this.selectedVideo = 0;
      },
    },
    config: {
      deep: true,
      // immediate:true,
      async handler() {
        await db
          .collection("config")
          .doc("meta")
          .update(this.config);
      },
    },
    "socialMedia.hashtag": {
      async handler() {
        await db
          .collection("config")
          .doc("socialMedia")
          .update({ hashtag: this.socialMedia.hashtag });
      },
    },
  },
  async mounted() {
    this.listFiles();
    this.getJudgingSheets();
    this.team = (await adminListAdmins()).data;
  },
  methods: {
    async addCriteria() {
      await db.collection("judgingcriteria").add({
        title: "New Title",
        phase: this.selectedPhase,
        section: this.selectedVideo,
      });
    },
    addPrompt(criteria) {
      // console.log(criteria);
      if (!criteria.criteria) this.$set(criteria, "criteria", []);

      criteria.criteria.push({
        title: "New Title",
        steps: map(this.getNumberVideos(this.selectedPhase), () => {
          return "";
        }),
      });
    },
    removePrompt(question, ind) {
      // console.log(question);
      question.splice(ind, 1);
    },
    getCurrentCriteria() {
      let tt = find(this.judgingCriteria, {
        phase: this.selectedPhase,
        section: this.selectedVideo,
      });

      // v-if="criteria.phase == selectedPhase && criteria.section == selectedVideo"
      if (tt) return tt;
      else
        return {
          empty: true,
          // phase: this.selectedPhase,
          // section: this.selectedVideo,
          // description: "New Description",
        };
      // return Object.assign({}, tt);
    },
    updateFreeText(phase, question) {
      // console.log(question);

      if (!question.steps) {
        this.$set(
          question,
          "steps",
          map(this.getNumberVideos(phase), () => {
            return "";
          })
        );
        // console.log(question.steps);
      }
    },
    async saveCriteria() {
      this.savingCriteria = true;
      try {
        //for each doc in judging:
        for (let crit of this.judgingCriteria) {
          // console.log(crit);
          await db
            .collection("judgingcriteria")
            .doc(crit.id)
            .update(crit);
        }
      } catch (e) {
        this.showError(e);
      } finally {
        this.savingCriteria = false;
      }
    },
    getNumberVideos(phase) {
      // console.log(phase);
      if (!phase) return 0;

      let vids = find(this.config.phases, { code: phase }).noOfVideos;
      // console.log(range(vids));

      return map(range(vids), (m) => {
        return {
          label: m,
          value: m,
        };
      });
    },
    async cancelInvite(person) {
      await db
        .collection("config")
        .doc("userRoles")
        .collection("users")
        .doc(person.auth.email)
        .delete();
      this.team = (await adminListAdmins()).data;
    },
    async addInvite() {
      if (this.newperson.email && this.newperson.roles.length) {
        await db
          .collection("config")
          .doc("userRoles")
          .collection("users")
          .doc(this.newperson.email)
          .set({ roles: this.newperson.roles });

        //refresh:
        this.team = (await adminListAdmins()).data;

        this.newperson.email = "";
      }
    },
    async updateRole(event, obj) {
      // console.log(event, obj);
      if (obj.invite) {
        await db
          .collection("config")
          .doc("userRoles")
          .collection("users")
          .doc(obj.auth.email)
          .update({ roles: event });
      } else {
        // console.log("user");
        await db
          .collection("users")
          .doc(obj.auth.uid)
          .update({ roles: event });
      }
    },
    codeToLang(code) {
      return langmap[code];
    },
    async getUsers() {
      this.downloadUsers = true;
      let users = (await adminListUsers()).data;
      this.downloadUsers = false;
      // console.log(users);
      exportFile("contributors.csv", users);
    },
    async getSubmissionsExport() {
      this.downloadUsers = true;
      let users = (await adminExportSubmissions()).data;
      this.downloadUsers = false;
      // console.log(users);
      exportFile("submissions.csv", users);
    },
    listFiles() {
      storage
        .ref()
        .child(`dropbox/`)
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            this.files.push(itemRef);
          });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    },
    getJudgingSheets() {
      this.judgingSheets.splice(0);
      storage
        .ref()
        .child(`judging/`)
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            this.judgingSheets.push(itemRef);
          });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error(error);
        });
    },
    async upload() {
      const filename = `${this.inputVal.name}_${DateTime.now().toHTTP()}`;
      const storageRef = storage.ref();

      // [START storage_monitor_upload]
      var uploadTask = storageRef
        .child(`dropbox/${filename}.mp4`)
        .put(this.inputVal);

      this.uploading = true;
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log("Upload is " + progress + "% done");
          this.progress = progress;
          // switch (snapshot.state) {
          //   case firebase.storage.TaskState.PAUSED: // or 'paused'
          //     console.log("Upload is paused");
          //     break;
          //   case firebase.storage.TaskState.RUNNING: // or 'running'
          //     console.log("Upload is running");
          //     break;
          // }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error(error);
        },
        () => {
          this.uploading = false;
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          //   console.log("File available at", downloadURL);
          // });
        }
      );
    },
    getTime(t) {
      return DateTime.fromSeconds(t._seconds).toFormat("hh:mm:ss");
    },
    getDate(t) {
      return DateTime.fromSeconds(t._seconds).toFormat("yyyy/MM/dd");
    },
    updateTimeC(event, field) {
      let old = DateTime.fromSeconds(this.config[field]._seconds);

      // console.log(old);
      let newc = old.set({
        hour: event.split(":")[0],
        minute: event.split(":")[1],
      });

      // console.log(newc);
      this.config[field]._seconds = newc.toSeconds();

      // console.log(field);
    },
    updateDateC(event, field) {
      let old = DateTime.fromSeconds(this.config[field]._seconds);
      // console.log(event);

      // console.log(old);
      let newc = old.set({
        year: event.split("/")[0],
        month: event.split("/")[1],
        day: event.split("/")[2],
      });

      // console.log(newc);
      this.config[field]._seconds = newc.toSeconds();

      // console.log(field);
    },
    updateTime(event, index, field) {
      let old = DateTime.fromSeconds(this.config.phases[index][field]._seconds);

      // console.log(old);
      let newc = old.set({
        hour: event.split(":")[0],
        minute: event.split(":")[1],
      });

      // console.log(newc);
      this.config.phases[index][field]._seconds = newc.toSeconds();

      // console.log(field);
    },
    updateDate(event, index, field) {
      let old = DateTime.fromSeconds(this.config.phases[index][field]._seconds);
      // console.log(event);

      // console.log(old);
      let newc = old.set({
        year: event.split("/")[0],
        month: event.split("/")[1],
        day: event.split("/")[2],
      });

      // console.log(newc);
      this.config.phases[index][field]._seconds = newc.toSeconds();
    },

    updateLength($event, index, field) {
      console.log($event, index, field);

      //if copyphases, then set on all phases, not just this one:
      if ($event.copyphases) {
        for (let i = 0; i < this.config.phases.length; i++) {
          if (this.config.phases[i].hasSubmission)
            this.$set(this.config.phases[i], field, $event.length);
        }
      } else {
        this.$set(this.config.phases[index], field, $event.length);
      }
    },
    async doStartFeedbackEditing() {
      this.$q.loading.show({
        message: this.$t("waiting_for_allocation"),
      });
      try {
        const result = (await startFeedbackEditing(this.judging)).data;
        console.log(result);

        // this.$q.loading.hide();
        if (result.message) {
          this.judgeStarted = true;
          this.$q.notify({
            type: "positive",
            message: this.$t("judging_success"),
          });
        } else {
          console.error(result);
          this.$q.notify({
            type: "negative",
            message: this.$t("judging_error"),
          });
        }
      } catch (e) {
        console.error(e);
        this.$q.notify({
          type: "negative",
          message: this.$t("judging_error"),
        });
      } finally {
        this.getJudgingSheets();
        this.$q.loading.hide();
      }
    },
    async getDownloadLink(file) {
      let url = await file.getDownloadURL();
      openURL(url);
    },
    async doGetResultsSheet() {
      this.$q.loading.show({
        message: this.$t("waiting_for_allocation"),
      });
      try {
        const result = (await createJudgingResultSheet(this.judging)).data;
        console.log(result);

        // this.$q.loading.hide();
        if (result.message) {
          this.judgeStarted = true;
          this.$q.notify({
            type: "positive",
            message: this.$t("judging_success"),
          });
        } else {
          console.error(result);
          this.$q.notify({
            type: "negative",
            message: this.$t("judging_error"),
          });
        }
      } catch (e) {
        console.error(e);
        this.$q.notify({
          type: "negative",
          message: this.$t("judging_error"),
        });
      } finally {
        this.getJudgingSheets();
        this.$q.loading.hide();
      }
    },
    async doGetFullResultsSheet() {
      this.$q.loading.show({
        message: this.$t("waiting_for_allocation"),
      });
      try {
        const result = (await createJudgingResult(this.judging)).data;
        console.log(result);

        // this.$q.loading.hide();
        if (result.message) {
          this.judgeStarted = true;
          this.$q.notify({
            type: "positive",
            message: this.$t("judging_success"),
          });
        } else {
          console.error(result);
          this.$q.notify({
            type: "negative",
            message: this.$t("judging_error"),
          });
        }
      } catch (e) {
        console.error(e);
        this.$q.notify({
          type: "negative",
          message: this.$t("judging_error"),
        });
      } finally {
        this.getJudgingSheets();
        this.$q.loading.hide();
      }
    },
    async doStartJudgingAllocation() {
      this.$q.loading.show({
        message: this.$t("waiting_for_allocation"),
      });
      try {
        const result = (await startJudgingAllocation(this.judging)).data;
        console.log(result);

        // this.$q.loading.hide();
        if (result.message) {
          this.judgeStarted = true;
          this.$q.notify({
            type: "positive",
            message: this.$t("judging_success"),
          });
        } else {
          console.error(result);
          this.$q.notify({
            type: "negative",
            message: this.$t("judging_error"),
          });
        }
      } catch (e) {
        console.error(e);
        this.$q.notify({
          type: "negative",
          message: this.$t("judging_error"),
        });
      } finally {
        this.getJudgingSheets();
        this.$q.loading.hide();
      }
    },
    async savePhase() {
      try {
        await db
          .collection("config")
          .doc("meta")
          .update({
            current_phase: this.config.current_phase,
          });
      } catch (e) {
        this.showError(e);
      }
    },

    getChipColor(status) {
      if (status === "submitted") {
        return "warning";
      } else if (status === "readyformoderation") {
        return "grey";
      } else if (status === "moderated") {
        return "info";
      } else if (status === "rejected" || status == "error") {
        return "negative";
      } else if (
        status === "translated" ||
        status === "subtitled" ||
        status == "draft"
      ) {
        return "warning";
      } else if (status === "finalised" || status == "edited") {
        return "positive";
      } else {
        return "info";
      }
    },
    judgingStart() {
      // console.log(this.user);
      if (!this.user) return false;
      return includes(this.user.roles, "superadmin") && !this.judgeStarted;
    },
  },
  firestore: {
    config: db.collection("config").doc("meta"),
    socialMedia: db.collection("config").doc("socialMedia"),
    judgingCriteria: db.collection("judgingcriteria"),
  },
};
</script>

<style lang="sass"></style>
