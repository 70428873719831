import Vue from "vue";
import VueI18n from "vue-i18n";
import getBrowserLocale from "@/util/get-browser-locale";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// console.log(getBrowserLocale({ countryCodeOnly: true }));
let messages = loadLocaleMessages();

function getUsableLocale() {
  let real = getBrowserLocale({ countryCodeOnly: true });
  if (messages[real]) return real;
  else return "en";
}

export default new VueI18n({
  locale: getUsableLocale(),
  fallbackLocale: "en",
  messages: messages,
  silentTranslationWarn: true,
});
