import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=e7873c82&lang=pug&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QToolbarTitle,QSpace,QTabs,QRouteTab,QBadge,QPage,QTabPanels,QTabPanel});
