<template lang="pug">
div
  q-header(flat)
    q-toolbar
      //- q-toolbar-title(v-if="$q.screen.gt.sm") Kuento
      img(src="../assets/Logo_White.svg" style="height:25px")
      //- p Kuento
      q-space
      //- LocaleSwitcher
      ProfileMenu(:user="user")

    //- div {{user}}

    q-tabs.text-white(align="justify", outside-arrows, mobile-arrows)
      q-route-tab(
        
        to="/dashboard/config",
        v-if="isAdmin",
        name="config",
        icon="settings",
        :label="$t('config')"
      )
      q-route-tab(
        exact,
        to="/dashboard/admin",
        v-if="isAdmin",
        name="admin",
        icon="list",
        :label="$t('admin')"
      )
      q-route-tab(
        exact,
        to="/dashboard/moderator",
        v-if="isModerator",
        name="moderation",
        icon="gavel",
        :label="$t('moderation')"
      )
        q-badge(color="blue", floating, v-if="stats.readyformoderation") {{ stats.readyformoderation }}

  q-page
    q-tab-panels(v-model="tab", animated)
      q-tab-panel(name="undefined")
        div {{ $t('noaccess') }}
      q-tab-panel(v-if="isAdmin", name="config")
        Config(:section="section")
      q-tab-panel(v-if="isAdmin", name="admin")
        Admin
      q-tab-panel(v-if="isModerator", name="moderator")
        Moderation
</template>

<script>
import { mapState } from "vuex";
import { includes, first } from "lodash";
import { db } from "@/db";
import ProfileMenu from "@/components/ProfileMenu.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Admin from "@/components/dashboard/Admin.vue";
import Moderation from "@/components/dashboard/Moderation.vue";
import Config from "@/components/dashboard/Config.vue";
import { auth } from "../db";

export default {
  name: "Dashboard",
  props: {
    tab: {
      default: "",
    },
    section: {
      default: "",
    },
  },
  data: () => {
    return {
      stats: {},
    };
  },
  components: {
    ProfileMenu,
    LocaleSwitcher,
    Moderation,
    Admin,
    Config,
  },
  watch: {
    tab: {
      immediate: true,
      async handler() {
        // if (!this.user) {
        //   await auth.signOut();
        //   this.$router.push("/");
        // }

        if (this.tab == "") {
          this.$router.push("/dashboard/" + first(this.user.roles));
        }
      },
    },
    section: {
      immediate: true,
      handler() {
        if (this.section == "" && this.tab == "config") {
          this.$router.push("/dashboard/config/reports");
        }
      },
    },
    async user() {
      if (this.user) {
        // console.log(this.user);

        if (this.user.isAnonymous) {
          await auth.signOut();
          this.$router.push("/");
        }

        if (this.tab == "config" && includes(this.user.roles, "admin")) return;

        if (!includes(this.user.roles, this.tab)) {
          this.$router.push("/dashboard/" + first(this.user.roles));
        }
      }
    },
  },
  mounted() {
    // console.log(this.user);
  },
  computed: {
    isModerator() {
      if (!this.user) return false;

      return includes(this.user.roles, "moderator");
    },
    isAdmin() {
      if (!this.user) return false;

      return includes(this.user.roles, "admin");
    },
    isSuperAdmin() {
      if (!this.user) return false;

      return includes(this.user.roles, "superadmin");
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  firestore: {
    stats: db.collection("config").doc("stats"),
  },
};
</script>
