import { render, staticRenderFns } from "./Moderation.vue?vue&type=template&id=2d942ede&scoped=true&lang=pug&"
import script from "./Moderation.vue?vue&type=script&lang=js&"
export * from "./Moderation.vue?vue&type=script&lang=js&"
import style0 from "./Moderation.vue?vue&type=style&index=0&id=2d942ede&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d942ede",
  null
  
)

export default component.exports
import QScrollObserver from 'quasar/src/components/scroll-observer/QScrollObserver.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QResponsive from 'quasar/src/components/responsive/QResponsive.js';
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollObserver,QDialog,QCard,QInfiniteScroll,QSpinnerDots,QChip,QSeparator,QResponsive,QCarousel,QCarouselSlide,QImg,QResizeObserver,QScrollArea,QCardActions,QBtn,QTooltip,QPageSticky,QBtnToggle});
