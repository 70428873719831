import Vue from "vue";
import VueRouter from "vue-router";
import { getCurrentUser } from "../db";
import Login from "@/components/Login";
import Anonymous from "@/components/Anonymous";
import Dashboard from "@/pages/Dashboard";
import Judging from "@/pages/Judging";

// import Submission from "@/pages/Submission";
// import SubmissionList from "@/pages/SubmissionList";
// import MakeSubmission from "@/pages/MakeSubmission";
import SingleModeration from "@/pages/SingleModeration";

Vue.use(VueRouter);

// NOTES:
// /login (shows 2 login buttons)
// /dashboard (screen for management)
// /submission (screen for new submissions / listing submissions)
// /submission/:phase (submission forms)

const routes = [
  {
    path: "/dashboard/:tab?/:section?",
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true,
      requiresRole: true,
    },
  },
  {
    path: "/moderation/:code",
    component: SingleModeration,
    props: true,
  },
  {
    path: "/judging/:code",
    component: Judging,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/:token",
    name: "LoginToken",
    component: Login,
  },
  {
    path: "/anonymous",
    name: "Anonymous",
    component: Anonymous,
  },
  {
    path: "*",
    redirect: "/login",
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await getCurrentUser();
  // console.log("currentUser: ", currentUser);

  // console.log("currentUser: ", currentUser);
  // let isParticipant = false;

  // if (currentUser.isAnonymous) {
  // return next("/judging");
  // }

  // if (currentUser) {
  //   // IF USER HAS NO ROLES -- THEN THEY ARE A PARTICIPANT
  //   isParticipant = currentUser.roles ? false : true;
  // }

  // if (currentUser && currentUser.isAnonymous ) {
  //   console.log("judging");
  //   //   return next("/dashboard");
  // }

  // if (currentUser && to.path.startsWith("/login") && isParticipant)
  //   return next("/submission");

  if (currentUser && to.path.startsWith("/login") && !currentUser.isAnonymous)
    return next("/dashboard");

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // const requiresRole = to.matched.some((record) => record.meta.requiresRole);

  if (!requiresAuth) next();

  // if (currentUser && requiresRole) {
  //   // Kick back to submissions
  //   return next("/submission");
  // }

  // if (requiresAuth && requiresRole) return next();

  if (requiresAuth && !currentUser) next("login");
  else if (requiresAuth && currentUser) next();
});

export default router;
