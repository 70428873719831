<template lang="pug">
.q-ml-sm.q-mt-xs(v-if="value")
  q-icon(name="help")
    q-tooltip
      div.text-blue {name}&nbsp;
        span.text-white Name of participant
      div.text-green {date} &nbsp;
        span.text-white Name of participant
      div.text-orange {tags} &nbsp;
        span.text-white Name of participant
      div.text-purple {region} &nbsp;
        span.text-white Name of participant
      div.text-pink {language} &nbsp;
        span.text-white Name of participant
  .text-body.hover()
    span(v-html="format(value[lang]) || '- enter something -'")
    q-popup-edit(v-model="newstring" :validate="val => val.length > 0" @save="save()")
      q-input(v-model="newstring" dense autogrow borderless)
</template>

<script>
import { db } from "@/db";

export default {
  name: "EditInput",
  props: ["field", "value", "lang"],
  data: () => {
    return {
      newstring: "",
    };
  },
  watch: {
    lang: {
      immediate: true,
      handler() {
        if (this.value && this.lang) this.newstring = this.value[this.lang];
      },
    },
    value: {
      immediate: true,
      handler() {
        if (this.value && this.lang) this.newstring = this.value[this.lang];
      },
    },
  },
  methods: {
    format(val) {
      return val
        .replace("{name}", '<span class="text-blue">{name}</span>')
        .replace("{date}", '<span class="text-green">{date}</span>')
        .replace("{tags}", '<span class="text-green">{tags}</span>')
        .replace("{region}", '<span class="text-purple">{region}</span>')
        .replace("{language}", '<span class="text-purple">{language}</span>');
    },
    async save() {
      let upt = {};
      upt[this.field] = this.value;
      upt[this.field][this.lang] = this.newstring;
      await db
        .collection("config")
        .doc("socialMedia")
        .update(upt);
    },
  },
};
</script>

<style lang="scss">
.hover:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
