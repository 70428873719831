import { render, staticRenderFns } from "./Config.vue?vue&type=template&id=71818e50&lang=pug&"
import script from "./Config.vue?vue&type=script&lang=js&"
export * from "./Config.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QRouteTab,QTabPanels,QTabPanel,QSeparator,QSelect,QBtn,QList,QItem,QItemSection,QTab,QInput,QCheckbox,QCardSection,QFile,QBtnToggle,QToggle,QTimeline,QTimelineEntry,QIcon,QPopupProxy,QTime,QDate,QExpansionItem});qInstall(component, 'directives', {ClosePopup});
