import langmap from "langmap";
import { DateTime } from "luxon";

export default {
  methods: {
    codeToLang(val) {
      let l = langmap[val.toLowerCase()];
      return l ? l.englishName : val;
    },
    getTimeFromNow(field) {
      return DateTime.fromJSDate(field.toDate()).toRelative();
    },
  },
};
