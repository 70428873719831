<template lang="pug">
.row.q-col-gutter-sm
  .col
    q-card.overflow-hidden(flat bordered)
      .row.justify-between.items-center.q-px-sm
        .col.q-pa-xs
          .text-overline {{title}}
            span.text-grey-5(v-if="length") &nbsp;&middot; {{length || '?'}}s
        .col-auto(v-if="overlay")
          q-btn(dense flat @click="showSvgDialog=true" icon="settings")
        .col-auto
          q-btn(dense flat @click="showDialog=true" icon="upload")
      q-media-player(:source="`${config.cloudfronturl}/assets/${phase}/${name}_${lang}${(ext)?ext:'.mp4'}`" :ratio="16/9" :autoplay="false" v-if="!image")
      //- div {{`${config.cloudfronturl}/assets/${phase}/${name}_${lang}.png?${Date.now()}`}}
      img.bg-grey-1(:src="`${config.cloudfronturl}/assets/${phase}/${name}_${lang}.png`" :ratio="16/9" v-if="image")
      q-dialog(ref="svgdialog" v-model="showSvgDialog" maximized)
        q-card.q-dialog-plugin
          q-card-section
            .text-h6 Overlay Text Rendering
          q-separator
          q-card-section.row
            .col-12.col-md-6
              q-input(v-model="currentSvg" type="textarea")
            .col-12.col-md-6.relative-position
              q-img.bg-grey-3(:src="`${config.cloudfronturl}/assets/${phase}/${name}_${lang}.png`" style="width:1920px;height:1080px;transform:scale(0.3);transform-origin:left top")
              div.absolute-top-left(v-html="currentSvg" style="transform:scale(0.3);transform-origin:left top")
          q-separator
          q-card-actions(align="right")
            q-btn(flat @click="saveSvg" :disable="svgSaving") Save
      q-dialog(ref="dialog" v-model="showDialog")
        q-card.q-dialog-plugin
          q-card-section 
            .text-h6 Upload New 
              span.text-primary {{name}}&nbsp;
              span in&nbsp;
              span.text-primary {{lang}}
          q-separator
          q-card-section 
            q-file(
              v-model="inputVal",
              filled
              label="select file"
              :accept="(image)?'.png':(ext)?ext:'.mp4, video/*'",
              :disable="uploading"
              lazy-rules,
              dense
              :rules="[(val) => !!val || 'file required']"
            )
              template(v-slot:append)
                q-circular-progress(:value="progress" v-show="uploading")
          
            q-separator
            q-checkbox(v-model="copylangs" :disable="uploading") Use this for all languages in this phase
            q-checkbox(v-model="copyphases" :disable="uploading") Use this for {{name}} in all phases
            q-input(v-model="newLength" type="numeric" :disable="uploading" outlined dense label="Video length" v-if="length").q-mx-sm.q-mt-sm
              template(v-slot:after) secs
          q-separator
          q-card-actions(align="right")
            q-btn(flat @click="upload" :disable="uploading") Upload
  </template>

<script>
import { storage, copyAsset,db } from "@/db";
// import { db } from "../db";
import UploadDialog from "./UploadDialog.vue";
import { find, findIndex } from "lodash";

export default {
  name: "UploadAsset",
  props: [
    "title",
    "phase",
    "config",
    "name",
    "lang",
    "image",
    "ext",
    "length",
    "transitionindex",
    "overlay",
  ],
  components: { UploadDialog },
  mounted() {
    let ph = find(this.config.phases, { code: this.phase });
    // console.log(ph);
    this.currentSvg = ph.svg_overlay;
  },
  data: () => {
    return {
      inputVal: null,
      uploading: false,
      progress: 0,
      showDialog: false,
      copylangs: false,
      copyphases: false,
      newLength: null,
      showSvgDialog: false,
      svgSaving: false,
      currentSvg: "",
    };
  },
  watch: {},
  computed: {
    extension() {
      return this.image ? ".png" : this.ext ? this.ext : ".mp4";
    },
    // currentSvg(){
    //   let ph = find(this.config.phases,{code:this.phase});
    //   return ph.overlay_svg;
    // }
  },
  methods: {
    async saveSvg() {
      this.svgSaving = true;

      let index = findIndex(this.config.phases,{code:this.phase});
      // let obj = {};
      // obj[`phases.${index}.svg_overlay`] = this.currentSvg;
      this.config.phases[index].svg_overlay = this.currentSvg;
      console.log(this.config.phases)
      await db.collection('config').doc("meta").update({phases:this.config.phases});
      this.svgSaving = false;
      this.showSvgDialog = false;
    },
    async upload() {
      // const filename = `${this.inputVal.name}_${DateTime.now().toHTTP()}`;

      // console.log("uploading");

      if (this.inputVal) {
        const filename = `assets/${this.phase}/${this.name}_${this.lang}${
          this.image ? ".png" : this.ext ? this.ext : ".mp4"
        }`;
        const storageRef = storage.ref();

        // console.log();

        // [START storage_monitor_upload]
        var uploadTask = storageRef.child(filename).put(this.inputVal);

        this.uploading = true;
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progress = progress;
          },
          (error) => {
            // Handle unsuccessful uploads
            // console.error(error);
            this.showError(error);
          },
          async () => {
            try {
              //set length:
              this.$emit("lengthUpdated", {
                length: this.newLength,
                copyphases: this.copyphases,
              });

              if (this.copylangs || this.copyphases)
                await copyAsset({
                  phase: this.phase,
                  lang: this.lang,
                  name: this.name,
                  ext: this.extension,
                  copylangs: this.copylangs,
                  copyphases: this.copyphases,
                });
            } catch (e) {
              this.showError(e);
            } finally {
              this.uploading = false;
              this.inputVal = null;
              this.showDialog = false;
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="scss"></style>
