<template lang="pug">
q-layout
  q-page-container
    router-view(:key="$route.fullPath")
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  meta() {
    return {
      title: this.$t("page_title"),
    };
  },
  mounted() {
    this.authAction();
  },
  methods: {
    ...mapActions(["authAction"]),
  },
};
</script>
