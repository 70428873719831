<template lang="pug">
.q-gutter-sm.row.items-center.no-wrap
  q-btn(round, flat)
    q-avatar(size="32px")
      q-img(v-if="this.user && this.user.photoURL", :src="this.user.photoURL" style="min-height:32px;min-width:32px;")
        template(v-slot:error)
          q-icon(name="person")

    q-menu(v-if="this.user")
      q-list.z-max.rounded-borders(bordered, padding, dense)
        q-item
          q-item-section(avatar)
            q-icon.text-primary(name="person")
          q-item-section {{ this.user.displayName }}
        q-item
          q-item-section(avatar)
            q-icon.text-primary(name="email")
          q-item-section {{ this.user.email }}
        q-item.justify-center
          q-btn.text-primary(
            :label="$t('sign_out')",
            style="max-width: 120px !important",
            flat,
            @click="signOut"
          )
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProfileMenu",
  props: ["user"],
  methods: {
    async signOut() {
      await this.signOutAction();
      this.$router.replace("/login");
    },
    ...mapActions(["signOutAction"]),
  },
};
</script>
