<template lang="pug">
div
  q-dialog(v-model="playVideo")
    q-card(style="width: 800px; max-width: 90vm")
      q-video(
        v-if="currentVideo",
        :src="getS3Link(currentVideo.src)",
        :ratio="16 / 9"
      )

  q-header(v-if="!error && !loading", flat)
    q-toolbar
      q-toolbar-title {{ $t('page_title_single') }}
      q-space
      LocaleSwitcher

  q-page(padding)
    q-banner.text-white.bg-red.q-pa-lg(rounded, v-if="error && !loading")
      .text-body1 {{ error }}

    q-banner.text-white.bg-green(
      rounded,
      v-if="!error && !loading && (submission.status == 'moderated' || submission.status == 'rejected')"
    )
      .text-body1 {{ $t('thanks_for_your_time') }}

    div(v-if="!error && !loading && submission.status == 'readyformoderation'")
      ModerationInstructions(v-if="!error && !loading")
      q-card.row.q-my-sm(flat, bordered)
        q-img.q-mb-sm.cursor-pointer(
          v-for="(item, idx) in getRawVideos(submission)",
          :key="item.id",
          :name="idx",
          @click="playVideo = true; currentVideo = item",
          :src="getThumbnail(item)"
        )
          .absolute-bottom.text-center {{ idx + 1 }} of {{ getRawVideos(submission).length }}

        q-card-section
          .text-h6 {{ getTextField(submission.media, 'title') }}
          div
            q-chip.on-left.text-uppercase(
              rounded,
              color="primary",
              text-color="white",
              v-for="tag of submission.tags",
              :key="tag"
            ) {{ $t('tags.' + tag) }}
            div
              div(
                v-for="media of submission.media",
                v-if="media.modality == 'text'",
                :key="media.id"
              )
                strong.text-grey-5.text-uppercase {{ media.type }}
                .q-mb-sm {{ getText(media) }}
      .text-center
        q-btn.on-left(
          round,
          size="lg",
          color="positive",
          icon="check",
          @click="updateStatus(submission, 'moderated')"
        )
          q-tooltip Approve

        q-btn(
          round,
          size="lg",
          color="negative",
          icon="not_interested",
          @click="updateStatus(submission, 'rejected')"
        )
          q-tooltip Deny
</template>

<script>
import { filter, find } from "lodash";
import { db } from "@/db";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import ModerationInstructions from "@/components/ModerationInstructions.vue";
import { DateTime } from "luxon";
import langmap from "langmap";

export default {
  name: "SingleModeration",
  props: ["code"],
  data: () => {
    return {
      submission: null,
      error: null,
      loading: true,
      config: {},
      slide: 0,
      tmpsub: {},
      playVideo: false,
      currentVideo: {},
      useoriginal: {
        use: false,
      },
    };
  },
  components: {
    LocaleSwitcher,
    ModerationInstructions,
  },
  methods: {
    async updateStatus(submission, status) {
      console.log("Change status", status);
      if (status == "rejected")
        this.$q
          .dialog({
            title: this.$t("reject_submission"),
            message: this.$t("reject_reason"),
            prompt: {
              model: "",
              type: "text", // Optional
            },
            cancel: true,
            persistent: true,
          })
          .onOk(async (data) => {
            await db
              .collection("submissions")
              .doc(submission.id)
              .update({ status: status, rejectreason: data });
            submission.status = status;
          });
      else {
        await db
          .collection("submissions")
          .doc(submission.id)
          .update({ status: status });
        submission.status = status;
      }
    },
    getRawVideos(submission) {
      let vids = filter(submission.media, { type: "raw" });
      return vids;
    },
    codeToLang(val) {
      let l = langmap[val.toLowerCase()];
      return l ? l.englishName : val;
    },
    getOriginalLang(submission) {
      let title = find(submission.media, { type: "title" });
      if (!title) return "??";
      let original = find(title.langs, { original: true });
      if (original) return this.codeToLang(original.srcLang);
      else return "??";
    },
    getCurrentVideo(submission) {
      const hdVideo = filter(submission.media, { type: "hd_video" });
      return hdVideo[0];
    },
    getTimeFromNow(field) {
      return DateTime.fromJSDate(field.toDate()).toRelative();
    },
    getText(media) {
      return find(media.langs, { original: true }).srcText;
    },
    getS3Link(src) {
      if (!src) return "";
      return `${this.config.cloudfronturl}${src
        .replace("s3://", "")
        .replace(this.config.bucketname, "")}`;
    },
    getMediaField(type, submission) {
      let lf = filter(submission.media, { type: type });
      if (!lf) return null;

      return lf;
    },
    getTextField(submission, type) {
      let lf = find(submission.media, { type: type });
      if (!lf) return null;
      return this.getText(lf);
    },
    getThumbnail(media) {
      let url = `${this.getS3Link(media.src).replace(".mp4", "")}.0000001.jpg`;
      return url;
    },
  },
  watch: {
    code: {
      immediate: true,
      async handler() {
        console.log(this.code);
        // let submission = await .get();
        try {
          // let submission = await db
          //   .collection("submissions")
          //   .doc(this.code)
          //   .get();

          await this.$bind(
            "tmpsub",
            db.collection("submissions").doc(this.code),
            {
              maxRefDepth: 2,
            }
          );

          this.submission = this.tmpsub;
          // console.log(this.submission);

          if (this.submission) {
            // this.submission = submission.data();
            if (this.submission.status != "readyformoderation")
              this.error = this.$t("already_moderated");
          } else {
            this.error = this.$t("invalid_link");
            // console.log('invalid submission');
          }
        } catch (e) {
          this.error = this.$t("invalid_link");
          this.error = e.toString();
        } finally {
          this.loading = false;
        }
      },
    },
  },
  computed: {},
  firestore: {
    config: db.collection("config").doc("meta"),
  },
};
</script>
