<template lang="pug">
q-layout
  q-page-container
    q-page.text-center
      .absolute-center
        .row
          //- .col-12
            //- img.logo(src="~@/assets/img/logo.gif" style="width:50px;")
          //- .col-12
            img.logo(src="~@/assets/img/climatered-tv-logotypes-07.png")
          .col-12.q-mt-lg
            q-btn(
              rounded,
              :size="$q.screen.lt.sm ? 'sm' : $q.screen.lt.md ? 'md' : 'lg'",
              color="primary",
              @click="signInWithGoogle()"
            )
              q-icon(left, :name="fabGoogle")
              div {{ $t('facilitators_login') }}
</template>

<script>
import { mapActions } from "vuex";
import { fabGoogle } from "@quasar/extras/fontawesome-v5";
import { db } from "../db";

export default {
  data() {
    return {
      accessToken: null,
      email: null,
      emailLogin: false,
      sentEmail: false,
    };
  },
  async created() {
    this.fabGoogle = fabGoogle;

    const token = this.$route.params.token;

    if (token) {
      this.$q.loading.show({
        // message: this.$t("loading"),
      });
      await this.signInAction({ token: token });
      this.$router.replace("/submission");
      this.$q.loading.hide();
    }
  },
  methods: {
    ...mapActions(["signInAction", "googleSignInAction"]),
    // signInWithLinkedIn() {
    //   window.location.href =
    //     "https://us-central1-youthinnovation-firebase.cloudfunctions.net/redirect";
    // },
    async signInWithGoogle() {
      const self = this;

      // console.log("user: ", user);
      this.$q.loading.show({
        // message: "Loading...",
      });

      const user = await self.googleSignInAction();

      const uid = user.user.uid;
      console.log(uid);
      const snapshot = await db
        .collection("users")
        .doc(uid)
        .get();
      // console.log("snapshot.data(): ", snapshot.data());

      if (!snapshot.data()) {
        self.$q.loading.show({
          message: this.$t("verify_account"),
        });
        setTimeout(function() {
          self.$q.loading.hide();
          return self.$router.replace("/dashboard");
        }, 5000);
      } else {
        self.$q.loading.hide();
        return self.$router.replace("/dashboard");
      }
    },
  },
};
</script>

<style>
.logo {
  opacity: 1;
  transition: all 0.3s;
  width: 60vw;
  max-width: 500px;
}
</style>
